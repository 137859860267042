import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';

export default function ProModal({ 
  setShowProModal, 
  freeProItemsRemaining,
  isForUnlimited = false,
  onUpgradeSuccess
}) {
  const navigate = useNavigate();

  const basicFeatures = [
    '2 free invoices total',
    '2 free client agreements total',
    'Bookable profiles',
    'Basic reporting',
    'Mobile app access',
  ];

  const proFeatures = [
    'Unlimited invoices',
    'Unlimited client agreements',
    'Custom branded invoices',
    'Priority support',
  ];

  const handleUpgrade = () => {
    navigate('/subscription');
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white dark:bg-black rounded-xl max-w-md w-full overflow-hidden border dark:border-white">
        <div className="bg-gradient-to-r from-green-500 to-green-600 p-6">
          <h2 className="text-2xl font-bold text-white">
            {isForUnlimited 
              ? 'Upgrade to Unlimited'
              : `${freeProItemsRemaining === 0 
                  ? 'No free pro items remaining'
                  : `${freeProItemsRemaining} free pro items remaining`}`
            }
          </h2>
          <p className="text-green-100 mt-2">
            {isForUnlimited 
              ? 'Billed $24 every 3 months'
              : '$0/forever'
            }
          </p>
        </div>

        <div className="p-6">
          <div className="space-y-4 mb-6">
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold">$8</span>
              <span className="text-gray-600 dark:text-white">/month</span>
              <span className="ml-2 text-sm text-green-600 bg-green-100 dark:bg-green-900 dark:text-green-100 px-2 py-0.5 rounded-full">
                Save 47% with quarterly billing
              </span>
            </div>
            <p className="text-sm text-gray-600 dark:text-white">
              or $15/month with monthly billing
            </p>
          </div>

          <div className="space-y-3 mb-6">
            {(isForUnlimited ? proFeatures : basicFeatures).map((feature) => (
              <div key={feature} className="flex items-start">
                <CheckIcon className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                <span className="ml-3 text-gray-700 dark:text-white">{feature}</span>
              </div>
            ))}
          </div>

          <button
            onClick={handleUpgrade}
            className="w-full bg-black text-white dark:bg-white dark:text-black py-3 rounded-lg 
                     font-medium hover:bg-black/80 dark:hover:bg-white/80 transition-colors duration-200"
          >
            {isForUnlimited ? 'Upgrade Now' : 'Continue'}
          </button>
          
          <button
            onClick={() => setShowProModal(false)}
            className="w-full mt-3 text-gray-600 dark:text-white hover:text-gray-800 
                     dark:hover:text-white/80 transition-colors duration-200"
          >
            Maybe Later
          </button>
        </div>

        <div className="px-6 pb-6">
          <p className="text-xs text-center text-gray-500 dark:text-white">
            Cancel anytime.
          </p>
        </div>
      </div>
    </div>
  );
}