import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from '@heroicons/react/24/outline';

export default function CreateProject() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [projectData, setProjectData] = useState({
    projectName: '',
    dueDate: null,
    tasks: [],
    status: 'In Progress'
  });

  const [taskName, setTaskName] = useState('');
  const [relativeDeadline, setRelativeDeadline] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw new Error('Must be logged in');

      const db = getFirestore();
      const projectRef = await addDoc(collection(db, 'projects'), {
        ...projectData,
        userId: user.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        dueDate: projectData.dueDate
      });

      navigate(`/project/${projectRef.id}`);
    } catch (error) {
      setError('Failed to create project: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const addTask = () => {
    if (!taskName || !relativeDeadline) {
      setError('Please provide both task name and deadline');
      return;
    }
    
    // Calculate task due date based on project due date and relative deadline
    const taskDueDate = new Date(projectData.dueDate);
    taskDueDate.setDate(taskDueDate.getDate() - parseInt(relativeDeadline));
    
    setProjectData(prev => ({
      ...prev,
      tasks: [
        ...prev.tasks,
        {
          taskName,
          relativeDeadline: parseInt(relativeDeadline),
          dueDate: taskDueDate,
          status: 'incomplete',
          id: Date.now()
        }
      ]
    }));
    setTaskName('');
    setRelativeDeadline('');
  };

  const removeTask = (taskId) => {
    setProjectData(prev => ({
      ...prev,
      tasks: prev.tasks.filter(task => task.id !== taskId)
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-black">
      <NavigationBar />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white dark:bg-black rounded-xl shadow-lg p-6 border border-gray-200 dark:border-gray-800">
          <h1 className="text-2xl font-bold mb-6">Create New Project</h1>
          
          {error && (
            <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">Project Name</label>
              <input
                type="text"
                value={projectData.projectName}
                onChange={(e) => setProjectData(prev => ({ ...prev, projectName: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-black"
                placeholder="Enter project name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Due Date</label>
              <div className="relative">
                <DatePicker
                  selected={projectData.dueDate}
                  onChange={date => setProjectData(prev => ({ ...prev, dueDate: date }))}
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy"
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-black"
                  placeholderText="Select due date"
                  required
                />
                <CalendarIcon className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium">Tasks</label>
              <div className="flex gap-4">
                <input
                  type="text"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  placeholder="Task name"
                  className="flex-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-black"
                />
                <select
                  value={relativeDeadline}
                  onChange={(e) => setRelativeDeadline(e.target.value)}
                  className="px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-black"
                >
                  <option value="">Select deadline</option>
                  <option value="1">1 day before</option>
                  <option value="3">3 days before</option>
                  <option value="7">7 days before</option>
                  <option value="14">14 days before</option>
                  <option value="30">30 days before</option>
                </select>
                <button
                  type="button"
                  onClick={addTask}
                  className="px-4 py-2 bg-gray-900 dark:bg-white text-white dark:text-black rounded-lg hover:opacity-90 transition-opacity"
                >
                  Add Task
                </button>
              </div>

              {projectData.tasks.length > 0 && (
                <div className="space-y-2">
                  {projectData.tasks.map(task => (
                    <div
                      key={task.id}
                      className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-900 rounded-lg"
                    >
                      <div>
                        <p className="font-medium">{task.taskName}</p>
                        <p className="text-sm text-gray-500">Due: {task.dueDate.toLocaleDateString()}</p>
                        <p className="text-sm text-gray-500">({task.relativeDeadline} days before project due date)</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeTask(task.id)}
                        className="text-red-500 hover:text-red-600 transition-colors"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={() => navigate('/projects')}
                className="px-6 py-2 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-900 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading || !projectData.projectName || !projectData.dueDate}
                className="px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg disabled:opacity-50 transition-colors"
              >
                {loading ? 'Creating...' : 'Create Project'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
