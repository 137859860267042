import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import NavigationBar from '../../NavigationBar';
import LoadingPage from './loadingPage';
import { db, storage } from '../../Firebase';
import { collection, addDoc, serverTimestamp, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import useCurrentUser from '../../Authentication/currentUser';
import mixpanel from 'mixpanel-browser';
import DarkModeButton from '../../DarkModeButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProModal from '../Invoice/ProModal';
import { XMarkIcon } from '@heroicons/react/24/outline';

const CreateAgreement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(() => {
    const auth = getAuth();
    return auth.currentUser;
  });
  const { username } = useCurrentUser();

  // Core state
  const [date, setDate] = useState(new Date());
  const [currency, setCurrency] = useState('USD');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [scopeOfWork, setScopeOfWork] = useState('');
  const [price, setPrice] = useState('');
  const [clientName, setClientName] = useState('');
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState('generative');
  const [manualAgreement, setManualAgreement] = useState('');
  
  // Pro features state
  const [referenceFiles, setReferenceFiles] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);

  // Status and loading state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  
  // Free pro agreement tracking
  const [freeProAgreementsRemaining, setFreeProAgreementsRemaining] = useState(2);
  const [monthlyAgreementCount, setMonthlyAgreementCount] = useState(0);
  const [showLimitWarning, setShowLimitWarning] = useState(false);

  useEffect(() => {
    const locationState = location.state;
    if (locationState?.source === 'client_card') {
      setClientName(locationState.clientName);
    }
  }, [location]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        await fetchUserStatus(user.uid);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchUserStatus = async (userId) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const isFullPro = userData.isPro || false;
        setIsProUser(isFullPro);
        
        if (!isFullPro) {
          const totalCount = userData.totalAgreementCount || 0;
          const remaining = 2 - totalCount;
          setFreeProAgreementsRemaining(remaining);
          setMonthlyAgreementCount(totalCount);
          
          if (remaining <= 1) {
            setShowLimitWarning(true);
          }
        }
        
        if (isFullPro) {
          await fetchReferenceFiles(userId);
        }

        // Track number of client agreements per user
        mixpanel.track("Client Agreements Count", {
          "User": username,
          "Total Agreements": userData.totalAgreements || 0,
          "Monthly Count": userData.monthlyAgreementCount || 0,
          "Is Pro User": isFullPro
        });
      }
    } catch (error) {
      setError('Failed to load user data. Please refresh the page.');
    }
  };

  useEffect(() => {
    const today = new Date().toLocaleDateString();
    setTitle(`Agreement with ${clientName} - ${today}`);
  }, [clientName]);

  const fetchReferenceFiles = async (userId) => {
    const storageRef = ref(storage, `reference_files/${userId}`);
    const fileList = await listAll(storageRef);

    const filesPromises = fileList.items.map(async (item) => {
      const url = await getDownloadURL(item);
      return { name: item.name, url };
    });

    const files = await Promise.all(filesPromises);
    setReferenceFiles(files);
  };

  const handleUploadReference = async (file) => {
    if (!currentUser) return;

    const storageRef = ref(storage, `reference_files/${currentUser.uid}/${file.name}`);
    await uploadBytes(storageRef, file);
    await fetchReferenceFiles(currentUser.uid);
  };

  const handleDeleteReference = async (fileName) => {
    if (!currentUser) return;

    const storageRef = ref(storage, `reference_files/${currentUser.uid}/${fileName}`);
    await deleteObject(storageRef);
    await fetchReferenceFiles(currentUser.uid);

    if (selectedReference && selectedReference.name === fileName) {
      setSelectedReference(null);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      handleUploadReference(acceptedFiles[0]);
    }
  }, [currentUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: false
  });

  const handleAgreementCreation = async () => {
    if (!isProUser) {
      if (freeProAgreementsRemaining <= 0) {
        setShowProModal(true);
        return false;
      }
      
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          totalAgreements: increment(1)
        });
      } catch (error) {
        setError('Failed to update agreement count. Please try again.');
        return false;
      }
    }
    return true;
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const canCreateAgreement = await handleAgreementCreation();
      if (!canCreateAgreement) {
        setIsLoading(false);
        return;
      }

      mixpanel.track("Agreement Generation Initiated", { 
        "User": username, 
        "Client": clientName, 
        "Scope of Work": scopeOfWork, 
        "Price": price, 
        "Currency": currency,
        "Mode": mode
      });

      if (currentUser && currentUser.uid) {
        if (date instanceof Date) {
          let referenceFileUrl = selectedReference ? selectedReference.url : '';
          const formattedDate = date.toISOString().split('T')[0];
          let agreementText;

          if (mode === 'generative') {
            const contractData = {
              scope_of_work: scopeOfWork,
              price: parseFloat(price),
              currency: currency,
              due_date: formattedDate,
              freelancer_name: username,
              client_name: clientName,
              reference_file_url: referenceFileUrl,
            };

            const response = await fetch('https://klorah-fast-server.onrender.com/api/generate-contract', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(contractData),
            });

            if (!response.ok) throw new Error('Failed to generate agreement');
            const data = await response.json();
            agreementText = data.contract_text;
          } else {
            agreementText = manualAgreement;
          }

          const agreementData = {
            text: agreementText,
            name: title,
            date: formattedDate,
            amount: price,
            userId: currentUser.uid,
            created: serverTimestamp(),
            reference_file_url: referenceFileUrl,
            mode: mode,
            isProAgreement: true
          };

          const docRef = await addDoc(collection(db, 'agreements'), agreementData);
          navigate(`/share-agreement/${docRef.id}`);
          
          mixpanel.track("Agreement Generation Successful", { 
            "User": username, 
            "Client": clientName, 
            "Agreement ID": docRef.id,
            "Mode": mode
          });
        }
      }
    } catch (error) {
      setError(error.message || 'Failed to generate agreement');
      mixpanel.track("Agreement Generation Failed", { 
        "User": username, 
        "Client": clientName, 
        "Error": error.message,
        "Mode": mode
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (event) => {
    const newDate = new Date(event.target.value);
    setDate(newDate);
  };

  if (isLoading) return <LoadingPage />;

  if (!currentUser?.emailVerified) {
    return (
      <div className="text-black dark:text-white min-h-screen">
        <NavigationBar />
        <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-lg text-red-600">
              Please verify your email to create agreements.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <NavigationBar />
      <DarkModeButton />

      {!isProUser && (
        <div className="bg-gradient-to-r from-green-100 to-green-50 dark:from-green-900 dark:to-green-800 px-4 py-3">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <p className="text-green-800 dark:text-green-200 text-sm flex items-center gap-2">
              <span className="font-medium">{`${freeProAgreementsRemaining}`}</span>
              free pro {freeProAgreementsRemaining === 1 ? 'agreement' : 'agreements'} remaining
              {showLimitWarning && (
                <span className="text-green-600 dark:text-green-300 font-medium">
                  • Last free agreement!
                </span>
              )}
            </p>
            <button 
              onClick={() => setShowProModal(true)}
              className="text-sm bg-green-500 hover:bg-green-600 text-white px-4 py-1.5 rounded-full transition-colors duration-200"
            >
              Upgrade to Unlimited
            </button>
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 dark:bg-red-900 p-4">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <p className="text-red-800 dark:text-red-200">{error}</p>
            <button onClick={() => setError(null)}>
              <XMarkIcon className="h-5 w-5 text-red-800 dark:text-red-200" />
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center p-5 pt-20">
        <div className="w-full max-w-2xl bg-white dark:bg-black rounded-lg border border-gray-300 dark:border-white p-9">
          <div className="flex justify-center mb-5 border-b border-gray-300 dark:border-white">
            <button
              className={`px-4 py-2 mr-4 font-medium text-lg focus:outline-none ${
                mode === 'generative'
                  ? 'text-black dark:text-white border-b-2 border-black dark:border-white'
                  : 'text-gray-500 dark:text-white'
              }`}
              onClick={() => setMode('generative')}
            >
              Generative
            </button>
            <button
              className={`px-4 py-2 font-medium text-lg focus:outline-none ${
                mode === 'manual'
                  ? 'text-black dark:text-white border-b-2 border-black dark:border-white'
                  : 'text-gray-500 dark:text-white'
              }`}
              onClick={() => setMode('manual')}
            >
              Manual
            </button>
          </div>

          <h1 className="text-2xl font-semibold mb-5">
            Create Agreement {mode === 'generative' ? 'using AI' : 'manually'}
          </h1>

          {mode === 'generative' ? (
            <>
              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">
                  Describe the scope of work for this contract
                </label>
                <input
                  type="text"
                  value={scopeOfWork}
                  onChange={(e) => setScopeOfWork(e.target.value)}
                  placeholder="Enter scope of work details"
                  className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
                />
              </div>

              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">Price</label>
                <div className="flex items-center gap-2">
                  <div className="relative w-1/3">
                    <button 
                      onClick={() => setDropdownVisible(!dropdownVisible)} 
                      className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 text-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      {currency}
                    </button>
                    {dropdownVisible && (
                      <div className="absolute top-full mt-1 bg-white dark:bg-black border border-gray-300 dark:border-white rounded-lg max-h-36 overflow-y-scroll z-10">
                        {['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'].map((item) => (
                          <button
                            key={item}
                            onClick={() => {
                              setCurrency(item);
                              setDropdownVisible(false);
                            }}
                            className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-black"
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Enter price"
                    className="w-2/3 h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="mb-6">
              <label className="block text-lg font-medium mb-2">Agreement Text</label>
              <ReactQuill
                value={manualAgreement}
                onChange={setManualAgreement}
                className="h-64 mb-12"
              />
            </div>
          )}

          <div className="flex items-center gap-2 mb-6">
            <div className="w-1/2">
              <label className="block text-lg font-medium mb-2">Client's Name</label>
              <input
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Enter client's name"
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-lg font-medium mb-2">Due Date</label>
              <input
                type="date"
                value={date.toISOString().split('T')[0]}
                onChange={onChange}
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">Agreement Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter agreement title"
              className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">Upload Reference Agreement</label>
            <div {...getRootProps()} className={`border-2 border-dashed border-gray-300 dark:border-white rounded-lg p-4 text-center cursor-pointer ${isDragActive ? 'bg-gray-100 dark:bg-black' : ''}`}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop a file here, or click to select a file</p>
            </div>
          </div>

          {referenceFiles.length > 0 && (
            <div className="mb-6">
              <label className="block text-lg font-medium mb-2">Select Reference Agreement</label>
              <div className="space-y-2">
                {referenceFiles.map((file) => (
                  <div key={file.name} className="flex items-center justify-between p-2 border rounded border-gray-300 dark:border-white">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id={file.name}
                        name="referenceFile"
                        checked={selectedReference && selectedReference.name === file.name}
                        onChange={() => setSelectedReference(file)}
                        className="mr-2"
                      />
                      <label htmlFor={file.name}>{file.name}</label>
                    </div>
                    <div>
                      <button
                        onClick={() => window.open(file.url, '_blank')}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        View
                      </button>
                      <button
                        onClick={() => handleDeleteReference(file.name)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <button
            onClick={handleGenerate}
            disabled={isLoading}
            className="w-full h-12 bg-black text-white rounded-lg hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading 
              ? 'Processing...' 
              : mode === 'generative' 
                ? 'Generate Agreement' 
                : 'Save Agreement'
            }
          </button>
        </div>
      </div>

      {showProModal && (
        <ProModal 
          setShowProModal={setShowProModal}
          freeProAgreementsRemaining={freeProAgreementsRemaining}
          monthlyAgreementLimit={2}
          onUpgradeSuccess={() => fetchUserStatus(currentUser.uid)}
          isForUnlimited={true}
        />
      )}
    </div>
  );
};

export default CreateAgreement;