import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import LoadingPage from '../Agreement/loadingPage';

const PaymentConfirmation = () => {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const firestore = getFirestore();
        const invoiceDocRef = doc(firestore, 'invoices', invoiceId);
        const invoiceDocSnap = await getDoc(invoiceDocRef);

        if (invoiceDocSnap.exists()) {
          const data = invoiceDocSnap.data();
          setInvoiceData(data);
          
          if (data.status === 'paid' && !data.confirmationSent) {
            await sendFreelancerConfirmation(data);
            // Update the document to set confirmationSent to true
            await updateDoc(invoiceDocRef, { confirmationSent: true });
          }
        } else {
          setError('Invoice not found.');
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
        setError(`An error occurred: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId]);

  const sendFreelancerConfirmation = async (data) => {
    if (data && data.freelancerEmail) {
      const freelancerEmailContent = `
        <h1>Payment Received</h1>
        <p>You have received a payment for your invoice.</p>
        <p>Invoice ID: ${invoiceId}</p>
        <p>Gig: ${data.gig}</p>
        <p>Customer: ${data.customer}</p>
        <p>Amount: ${data.currency} ${data.price}</p>
        <p>Payment Date: ${new Date().toLocaleDateString()}</p>
        <p>The payment has been processed and will be transferred to your account.</p>
      `;

      try {
        const response = await fetch('https://klorah-fast-server.onrender.com/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: data.freelancerEmail,
            subject: 'Payment Received',
            html_content: freelancerEmailContent,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to send freelancer confirmation email');
        }
      } catch (error) {
        console.error('Error sending freelancer confirmation:', error);
      }
    }
  };

  const handleSendCustomerConfirmation = async () => {
    if (!customerEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    const customerEmailContent = `
      <h1>Payment Confirmation</h1>
      <p>Thank you for your payment, ${invoiceData.customer}.</p>
      <p>Invoice ID: ${invoiceId}</p>
      <p>Gig: ${invoiceData.gig}</p>
      <p>Amount: ${invoiceData.currency} ${invoiceData.price}</p>
      <p>Payment Date: ${new Date(invoiceData.paidAt.seconds * 1000).toLocaleDateString()}</p>
      <p>If you have any questions, please contact us.</p>
    `;

    try {
      const response = await fetch('https://klorah-fast-server.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: customerEmail,
          subject: 'Payment Confirmation',
          html_content: customerEmailContent,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send confirmation email');
      }

      setError('');
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending customer confirmation:', error);
      setError('Failed to send confirmation email. Please try again.');
    }
  };

  if (isLoading) return <LoadingPage />;

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center px-4 bg-black dark:bg-white">
        <div className="bg-orange-100 border-l-4 border-orange-500 text-black p-4 rounded-lg shadow-md dark:bg-orange-700 dark:text-white" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4 bg-white text-black dark:bg-black dark:text-white">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl p-8 dark:bg-gray-900">
        <h1 className="text-3xl font-extrabold mb-6 text-center text-green-600 dark:text-green-400">Payment Confirmation</h1>
        {invoiceData && invoiceData.status === 'paid' ? (
          <div>
            <div className="mb-8 text-center">
              <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto dark:bg-green-800">
                <svg className="w-12 h-12 text-green-500 dark:text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mt-4">Payment Successful!</h2>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700">
                <p className="text-gray-600 dark:text-gray-400">Invoice ID</p>
                <p className="font-medium">{invoiceId}</p>
              </div>
              <div className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700">
                <p className="text-gray-600 dark:text-gray-400">Amount Paid</p>
                <p className="font-medium">{invoiceData.currency} {invoiceData.price}</p>
              </div>
              <div className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700">
                <p className="text-gray-600 dark:text-gray-400">Status</p>
                <p className="font-medium text-green-600 dark:text-green-400">PAID</p>
              </div>
              <div className="flex justify-between items-center py-2">
                <p className="text-gray-600 dark:text-gray-400">Payment Date</p>
                <p className="font-medium">{new Date(invoiceData.paidAt.seconds * 1000).toLocaleDateString()}</p>
              </div>
            </div>
            <div className="mt-8">
              {!showEmailInput ? (
                <button
                  onClick={() => setShowEmailInput(true)}
                  className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                  Receive confirmation email
                </button>
              ) : (
                <div>
                  <input
                    type="email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full mt-2 p-2 border rounded"
                  />
                  <button
                    onClick={handleSendCustomerConfirmation}
                    className="w-full mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Send Confirmation
                  </button>
                  {emailSent && (
                    <div className="mt-4 text-green-500">
                      Confirmation email sent successfully!
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center text-orange-600 bg-orange-50 p-4 rounded-lg dark:bg-orange-900 dark:text-orange-200">
            <p className="font-medium">Payment was not successful. Please try again or contact support.</p>
          </div>
        )}
        {error && (
          <div className="mt-4 text-red-500">{error}</div>
        )}
        <div className="mt-8 text-center text-sm text-gray-500 dark:text-gray-400">
          <p>If you have any questions, please contact <a href="mailto:elvin@klorah.com" className="text-green-600 hover:underline dark:text-green-400">elvin@klorah.com</a>.</p>
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm text-gray-500 dark:text-gray-400">Powered by <a href="https://klorah.app" className="text-green-600 font-medium hover:underline dark:text-green-400">Klorah</a></p>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;