import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <footer className="py-16 bg-black dark:bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div className="col-span-1">
            <img
              className="h-8 w-auto"
              src="/klorahLogo.png"
              alt="Klorah"
            />
            <p className="mt-4 text-sm text-gray-400 dark:text-gray-600">
              Located in New York City, empowering freelancers with innovative financial solutions.
            </p>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-800 uppercase tracking-wider">
              Company
            </h3>
            <ul className="mt-4 space-y-3">
              <li>
                <a href="/deck" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500 text-sm">
                  Pitch Deck
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-800 uppercase tracking-wider">
              Legal
            </h3>
            <ul className="mt-4 space-y-3">
              <li>
                <a href="/privacy-policy" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500 text-sm">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/tos" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500 text-sm">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-800 uppercase tracking-wider">
              Social
            </h3>
            <div className="mt-4 flex space-x-6">
              <a href="https://www.instagram.com/klorahapp/?hl=en" target="_blank" rel="noopener noreferrer" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
                <span className="sr-only">Instagram</span>
              </a>
              <a href="https://x.com/klorahapp" target="_blank" rel="noopener noreferrer" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
                <span className="sr-only">Twitter</span>
              </a>
              <a href="https://www.linkedin.com/company/klorah/" target="_blank" rel="noopener noreferrer" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
                <span className="sr-only">LinkedIn</span>
              </a>
              <a href="https://www.tiktok.com/@klorah" target="_blank" rel="noopener noreferrer" className="text-gray-400 dark:text-gray-600 hover:text-gray-300 dark:hover:text-gray-500">
                <FontAwesomeIcon icon={faTiktok} size="lg" />
                <span className="sr-only">TikTok</span>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800 dark:border-gray-200">
          <p className="text-sm text-gray-400 dark:text-gray-600">
            &copy; {new Date().getFullYear()} Klorah, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}