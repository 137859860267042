import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsOfService() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-4xl p-12 m-8">
        <button onClick={goBack} className="mb-8 flex items-center gap-2 text-black hover:opacity-70 transition-opacity">
          <span>←</span>
          <span className="font-medium">Back</span>
        </button>
        
        <h1 className="text-4xl font-bold mb-6 text-center text-black">Terms of Service</h1>
        <p className="mb-8 text-center text-black/60">Last Updated: January 5, 2025</p>

        <section className="space-y-12">
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Introduction</h2>
            <p className="text-black/80 leading-relaxed">
              Welcome to Klorah. By accessing or using our AI-powered billing and contract management platform, you agree to be bound by these Terms of Service. Please read them carefully before proceeding.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Acceptance of Terms</h2>
            <p className="text-black/80 leading-relaxed">
              By accessing or using Klorah's services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service, as well as our Privacy Policy. If you do not agree to these terms, you must not use our services.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Service Description</h2>
            <p className="text-black/80 leading-relaxed mb-4">
              Klorah provides an AI-powered platform for:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-black/80">
              <li>Automated billing and invoice generation</li>
              <li>Contract creation and management</li>
              <li>Payment processing and tracking</li>
              <li>Document storage and organization</li>
              <li>AI-assisted customer communication</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">User Obligations</h2>
            <p className="text-black/80 leading-relaxed mb-4">
              As a user of Klorah, you agree to:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-black/80">
              <li>Provide accurate and complete information when using our services</li>
              <li>Maintain the security of your account credentials</li>
              <li>Use the service in compliance with all applicable laws and regulations</li>
              <li>Not engage in any unauthorized or fraudulent activities</li>
              <li>Not attempt to disrupt or compromise our system's security</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Intellectual Property</h2>
            <p className="text-black/80 leading-relaxed">
              All content, features, and functionality of the Klorah platform, including but not limited to text, graphics, logos, and software, are owned by Klorah and are protected by international copyright, trademark, and other intellectual property laws.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Termination</h2>
            <p className="text-black/80 leading-relaxed">
              We reserve the right to suspend or terminate your access to Klorah, without prior notice or liability, for any reason, including breach of these Terms. Upon termination, your right to use the service will immediately cease, and you must cease all use of our platform.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Limitation of Liability</h2>
            <p className="text-black/80 leading-relaxed">
              To the maximum extent permitted by law, Klorah shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Governing Law</h2>
            <p className="text-black/80 leading-relaxed">
              These Terms shall be governed by and construed in accordance with the laws of the United States. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located within the United States.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Contact Information</h2>
            <p className="text-black/80 leading-relaxed">
              For any questions about these Terms of Service, please contact us at:
            </p>
            <div className="mt-4 p-6 bg-black/5 rounded-lg">
              <p className="text-black/80">Email: <a href="mailto:elvin@klorah.com" className="text-black underline hover:opacity-70">elvin@klorah.com</a></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TermsOfService;
