import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faUsers, faProjectDiagram, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../Firebase';
import useCurrentUser from '../Authentication/currentUser';

const UserNameFrame = () => {
  const { username } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(username);

  useEffect(() => {
    setEditedName(username);
  }, [username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editedName.trim() && editedName !== username) {
      try {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDoc, { username: editedName.trim() });
      } catch (error) {
        console.error('Error updating username:', error);
      }
    }
    setIsEditing(false);
  };

  return (
    <div className="w-full text-center mb-6">
      <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-normal text-black dark:text-white">
        Welcome back, <span className="font-bold">{username}</span>
      </h1>
    </div>
  );
};

const TopHalf = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    const user = auth.currentUser;
    if (user && user.uid) {
      const invoicesQuery = query(collection(db, 'invoices'), where('userId', '==', user.uid));
      const clientsQuery = query(collection(db, `users/${user.uid}/clients`));
      const projectsQuery = query(collection(db, 'projects'), where('userId', '==', user.uid));

      const unsubscribeInvoices = onSnapshot(invoicesQuery, snapshot => {
        setInvoiceCount(snapshot.size);
      });
      const unsubscribeClients = onSnapshot(clientsQuery, snapshot => {
        setClientCount(snapshot.size);
      });
      const unsubscribeProjects = onSnapshot(projectsQuery, snapshot => {
        setProjectCount(snapshot.size);
      });

      return () => {
        unsubscribeInvoices();
        unsubscribeClients();
        unsubscribeProjects();
      };
    }
  }, [currentUser]);

  return (
    <div className="w-full max-w-4xl mx-auto px-3 mb-6">
      <UserNameFrame />
      <div className="grid grid-cols-3 gap-3">
        <div onClick={() => navigate('/projects')} 
             className="col-span-2 bg-black dark:bg-[#1A1A1A] dark:bg-opacity-80 dark:backdrop-blur-md rounded-lg p-4 flex flex-col justify-between shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer transform hover:-translate-y-1">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-lg bg-[#F0F0F0] dark:bg-[#2A2A2A] dark:bg-opacity-80 dark:backdrop-blur-md flex items-center justify-center">
              <FontAwesomeIcon icon={faProjectDiagram} size="lg" className="text-black dark:text-white" />
            </div>
            <div className="flex-1">
              <div className="text-white text-sm font-semibold mb-1">Projects</div>
              <div className="text-white text-xl font-bold">{projectCount}</div>
            </div>
          </div>
          <div className="mt-3">
            <div className="text-white text-sm font-medium">{projectCount === 1 ? 'Active Project' : 'Active Projects'}</div>
            <div className="mt-3 inline-flex items-center gap-2 bg-white dark:bg-[#2A2A2A] dark:bg-opacity-80 dark:backdrop-blur-md px-3 py-1.5 rounded-full">
              <span className="text-black dark:text-white text-sm font-semibold">View Details</span>
              <FontAwesomeIcon icon={faChevronRight} size="sm" className="text-black dark:text-white" />
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <div onClick={() => navigate('/invoices')} 
               className="bg-white dark:bg-[#1A1A1A] dark:bg-opacity-80 dark:backdrop-blur-md rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer transform hover:-translate-y-1">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-[#F0F0F0] dark:bg-[#2A2A2A] dark:bg-opacity-80 dark:backdrop-blur-md flex items-center justify-center">
                <FontAwesomeIcon icon={faFileInvoice} size="lg" className="text-black dark:text-white" />
              </div>
              <div className="flex-1">
                <div className="text-black dark:text-white text-lg font-bold">{invoiceCount}</div>
                <div className="text-[#666666] dark:text-[#999999] text-sm font-medium">{invoiceCount === 1 ? 'Invoice' : 'Invoices'}</div>
              </div>
            </div>
          </div>

          <div onClick={() => navigate('/clients')} 
               className="bg-white dark:bg-[#1A1A1A] dark:bg-opacity-80 dark:backdrop-blur-md rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer transform hover:-translate-y-1">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-[#F0F0F0] dark:bg-[#2A2A2A] dark:bg-opacity-80 dark:backdrop-blur-md flex items-center justify-center">
                <FontAwesomeIcon icon={faUsers} size="lg" className="text-black dark:text-white" />
              </div>
              <div className="flex-1">
                <div className="text-black dark:text-white text-lg font-bold">{clientCount}</div>
                <div className="text-[#666666] dark:text-[#999999] text-sm font-medium">{clientCount === 1 ? 'Client' : 'Clients'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHalf;
