import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../NavigationBar';
import PendingBookings from './PendingBookings';
import { Squares2X2Icon, ListBulletIcon } from '@heroicons/react/24/outline';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot, doc, deleteDoc } from 'firebase/firestore';

export default function ProjectDashboard() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('grid');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch projects on mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.log('No user found');
          navigate('/login');
          return;
        }

        console.log('Fetching projects for user:', user.uid);
        
        const db = getFirestore();
        const projectsRef = collection(db, 'projects');
        const q = query(projectsRef, where('userId', '==', user.uid));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const projectsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log('Fetched projects:', projectsData);
          setProjects(projectsData);
          setLoading(false);
        }, (error) => {
          console.error('Snapshot error:', error);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error loading projects:', error);
        setLoading(false);
      }
    };
    fetchProjects();
  }, [navigate]);

  const handleDeleteProject = async (e, projectId) => {
    e.stopPropagation();
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'projects', projectId));
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  // Render a card in grid mode
  const GridCard = ({ project }) => {
    const { projectName, status, tasks = [], dueDate } = project;
    const completedTasks = tasks.filter(t => t.status === 'complete').length;
    const totalTasks = tasks.length || 1; // avoid division by zero
    const progress = Math.round((completedTasks / totalTasks) * 100);
    const daysRemaining = dueDate 
      ? Math.ceil((dueDate.toDate() - new Date()) / (1000 * 60 * 60 * 24))
      : 0;

    return (
      <div
        onClick={() => navigate(`/project/${project.id}`)}
        className="relative bg-white dark:bg-black p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-800 hover:shadow-lg transition-all duration-200 cursor-pointer"
      >
        <button
          onClick={(e) => handleDeleteProject(e, project.id)}
          className="absolute top-4 right-4 bg-white dark:bg-gray-800 p-1.5 rounded-full hover:bg-red-50 dark:hover:bg-red-900/30 transition-colors"
        >
          <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4
                     a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
        <div className="space-y-4">
          <div>
            <h2 className="font-semibold text-xl mb-1">{projectName}</h2>
            <span className={`inline-flex px-2.5 py-1 rounded-full text-xs font-medium
              ${status === 'In Progress' ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/30 dark:text-blue-400' :
                status === 'Completed' ? 'bg-green-50 text-green-600 dark:bg-green-900/30 dark:text-green-400' :
                'bg-yellow-50 text-yellow-600 dark:bg-yellow-900/30 dark:text-yellow-400'}`}>
              {status}
            </span>
          </div>
          
          <div className="space-y-3">
            <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
              <span>Progress</span>
              <span className="font-medium">{progress}%</span>
            </div>
            <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-2.5">
              <div
                className="bg-green-500 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600 dark:text-gray-400">
              {completedTasks} of {totalTasks} tasks
            </span>
            <span className="text-gray-600 dark:text-gray-400">
              {daysRemaining} days left
            </span>
          </div>
        </div>
      </div>
    );
  };

  // Render a card in list mode
  const ListCard = ({ project }) => {
    const { projectName, status, tasks = [], dueDate } = project;
    const completedTasks = tasks.filter(t => t.status === 'complete').length;
    const totalTasks = tasks.length || 1;
    const progress = Math.round((completedTasks / totalTasks) * 100);
    const daysRemaining = dueDate 
      ? Math.ceil((dueDate.toDate() - new Date()) / (1000 * 60 * 60 * 24))
      : 0;

    return (
      <div
        onClick={() => navigate(`/project/${project.id}`)}
        className="relative bg-white dark:bg-black p-4 rounded-xl shadow-sm border border-gray-100 dark:border-gray-800 hover:shadow-lg transition-all duration-200 cursor-pointer mb-4"
      >
        <button
          onClick={(e) => handleDeleteProject(e, project.id)}
          className="absolute top-4 right-4 bg-white dark:bg-gray-800 p-1.5 rounded-full hover:bg-red-50 dark:hover:bg-red-900/30 transition-colors"
        >
          <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4
                     a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
        
        <div className="flex items-center justify-between gap-4">
          <div className="flex-1">
            <div className="flex items-center justify-between mb-2">
              <h2 className="font-semibold text-lg">{projectName}</h2>
              <span className={`px-2.5 py-1 rounded-full text-xs font-medium
                ${status === 'In Progress' ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/30 dark:text-blue-400' :
                  status === 'Completed' ? 'bg-green-50 text-green-600 dark:bg-green-900/30 dark:text-green-400' :
                  'bg-yellow-50 text-yellow-600 dark:bg-yellow-900/30 dark:text-yellow-400'}`}>
                {status}
              </span>
            </div>
            
            <div className="flex items-center gap-4 text-sm text-gray-600 dark:text-gray-400">
              <span>{completedTasks} of {totalTasks} tasks</span>
              <span>•</span>
              <span>{daysRemaining} days remaining</span>
            </div>
          </div>
          
          <div className="w-32">
            <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-2">
              <div
                className="bg-green-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen">
        <NavigationBar />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-800 dark:border-white" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-black">
      <NavigationBar />
      <div className="max-w-7xl mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold mb-2">Projects</h1>
            <p className="text-gray-600 dark:text-gray-400">Manage your projects and track their progress</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex bg-white dark:bg-gray-800 p-1 rounded-lg shadow-sm">
              <button
                className={`p-2 rounded-md transition-colors ${viewMode === 'grid' 
                  ? 'bg-gray-900 text-white' 
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                onClick={() => setViewMode('grid')}
              >
                <Squares2X2Icon className="w-5 h-5" />
              </button>
              <button
                className={`p-2 rounded-md transition-colors ${viewMode === 'list' 
                  ? 'bg-gray-900 text-white' 
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                onClick={() => setViewMode('list')}
              >
                <ListBulletIcon className="w-5 h-5" />
              </button>
            </div>
            <button
              onClick={() => navigate('/projects/create')}
              className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg transition-colors"
            >
              New Project
            </button>
          </div>
        </header>

        {/* Pending bookings section */}
        <PendingBookings />

        {/* Projects section */}
        <div className="mt-8">
          {viewMode === 'grid' ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {projects.map((project) => (
                <GridCard key={project.id} project={project} />
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {projects.map((project) => (
                <ListCard key={project.id} project={project} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
