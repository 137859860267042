import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const Invite = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      // Redirect to signup with invite code
      navigate(`/signup?invite=${code}`);
    } else {
      // If no code provided, redirect to home
      navigate('/');
    }
  }, [code, navigate]);

  // Show loading state while redirecting
  return (
    <div className="min-h-screen bg-white dark:bg-black flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#CBFF5B] mx-auto"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-400">Redirecting...</p>
      </div>
    </div>
  );
}; 