import React, { useState } from 'react';

const BookingModal = ({ isOpen, onClose, service, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onSubmit(formData);
    // Keep button disabled for 1 minute after submission
    setTimeout(() => {
      setIsSubmitting(false);
    }, 60000);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-black rounded-2xl p-8 max-w-lg w-full shadow-2xl">
        <h3 className="text-2xl font-semibold text-[#161315] dark:text-[#CBFF5B] mb-6">
          Book {service.name}
        </h3>

        <div className="mb-6">
          <p className="text-[#161315] dark:text-white mb-2">{service.description}</p>
          <p className="text-2xl font-bold text-[#161315] dark:text-[#CBFF5B]">
            {service.currency} {service.price}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-[#161315] dark:text-white mb-1">
              Your Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black text-[#161315] dark:text-white"
              placeholder="Enter your name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#161315] dark:text-white mb-1">
              Your Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black text-[#161315] dark:text-white"
              placeholder="Enter your email"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#161315] dark:text-white mb-1">
              Message (Optional)
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black text-[#161315] dark:text-white h-32"
              placeholder="Tell the freelancer about your project"
            />
          </div>

          <div className="flex gap-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-6 py-3 border border-gray-200 dark:border-white text-[#161315] dark:text-white rounded-xl hover:bg-gray-50 dark:hover:bg-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`flex-1 px-6 py-3 rounded-xl ${
                isSubmitting 
                  ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                  : 'bg-[#CBFF5B] text-[#161315] hover:opacity-90'
              }`}
            >
              {isSubmitting ? 'Processing...' : 'Submit Booking'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingModal; 