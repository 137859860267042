import React, { useState, useEffect } from 'react';
import { auth } from '../Firebase';
import { generateInviteLink } from '../utils/emailService';
import toast from 'react-hot-toast';

export const InviteBanner = () => {
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const [isDismissed, setIsDismissed] = useState(() => {
    return localStorage.getItem('inviteBannerDismissed') === 'true';
  });

  const handleDismiss = () => {
    setIsDismissed(true);
    localStorage.setItem('inviteBannerDismissed', 'true');
  };

  const handleGenerateInvite = async () => {
    setIsGeneratingLink(true);
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error('User not authenticated');
      
      const link = await generateInviteLink(userId);
      await navigator.clipboard.writeText(link);
      toast.success('Invite link copied to clipboard!');
    } catch (error) {
      console.error('Error generating invite:', error);
      toast.error('Failed to generate invite link');
    } finally {
      setIsGeneratingLink(false);
    }
  };

  // Reset dismissed state when user logs in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Reset the dismissed state when a user logs in
        setIsDismissed(false);
        localStorage.removeItem('inviteBannerDismissed');
      }
    });

    return () => unsubscribe();
  }, []);

  if (isDismissed || !auth.currentUser) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 top-0 px-4 pt-4 sm:px-6 sm:pt-6">
      <div className="mx-auto max-w-2xl rounded-xl bg-black dark:bg-gray-900 p-4 sm:p-5 shadow-sm backdrop-blur-sm relative">
        <button 
          onClick={handleDismiss}
          className="absolute top-3 right-3 text-white/60 hover:text-white/80 transition-colors"
          aria-label="Dismiss banner"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>

        <div className="flex items-center gap-x-4">
          <div className="flex-1">
            <p className="text-2xl font-semibold text-white tracking-tight">
              Earn <span className="text-3xl text-[#CBFF5B]">$10 per invite</span>
            </p>
            <p className="mt-1 text-sm text-white/80">
              Invite friends to Klorah. You'll get $10 for each friend that completes their onboarding - no limits!
            </p>
          </div>
          <button
            className="flex-none rounded-lg bg-[#CBFF5B] px-3.5 py-2 text-sm font-medium text-black hover:opacity-90 transition-opacity"
            onClick={handleGenerateInvite}
            disabled={isGeneratingLink}
          >
            {isGeneratingLink ? 'Generating...' : 'Get invite link'}
          </button>
        </div>
      </div>
    </div>
  );
};