import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useOnboarding } from './OnboardingContext';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import axios from 'axios';
import NavigationBar from '../NavigationBar';
import LoadingPage from '../Dashboard/Agreement/loadingPage';

const stripePromise = loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');

const PRICE_IDS = {
  monthly: 'price_1QMa81LwpaCHfpZ1sR2trgFd',
  quarterly: 'price_1QMa81LwpaCHfpZ1EEsytrKu'
};

export default function SubscriptionPage() {
  const { isOnboardingComplete, updateOnboardingStatus, isLoading: onboardingLoading, user: onboardingUser } = useOnboarding();
  const navigate = useNavigate();
  const [billingPeriod, setBillingPeriod] = useState('quarterly');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [monthlyInvoiceCount, setMonthlyInvoiceCount] = useState(0);
  const [monthlyAgreementCount, setMonthlyAgreementCount] = useState(0);
  const [monthlyInvoiceLimit, setMonthlyInvoiceLimit] = useState(2);
  const [monthlyAgreementLimit, setMonthlyAgreementLimit] = useState(2);
  const [isPortalLoading, setIsPortalLoading] = useState(false);
  const [portalError, setPortalError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        await fetchUserStatus(user.uid);
        setLoading(false);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchUserStatus = async (userId) => {
    try {
      const firestore = getFirestore();
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsProUser(userData.isPro || false);
        setMonthlyInvoiceCount(userData.totalInvoiceCount || 0);
        setMonthlyAgreementCount(userData.totalAgreementCount || 0);
        setMonthlyInvoiceLimit(2);
        setMonthlyAgreementLimit(2);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const createStripeCustomer = async () => {
    const userEmail = auth.currentUser?.email;
    const userId = auth.currentUser?.uid;
    if (!userEmail || !userId) return null;

    try {
      const customerRes = await axios.post('https://klorah-fast-server.onrender.com/api/create-customer', {
        email: userEmail,
      }, {
        headers: {
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
      });

      return { stripeCustomerId: customerRes.data.stripe_customer_id, userId };
    } catch (error) {
      console.error('Error creating Stripe customer:', error);
      return null;
    }
  };

  const createCheckoutSession = async ({ stripeCustomerId, userId, billingPeriod }) => {
    try {
      const sessionRes = await axios.post('https://klorah-fast-server.onrender.com/api/createCheckoutSession', {
        userId,
        stripeCustomerId,
        priceId: PRICE_IDS[billingPeriod],
        billingPeriod
      });

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: sessionRes.data.sessionId });
      localStorage.setItem('isOnboardingComplete', 'true');
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const createCustomerPortalSession = async () => {
    setIsPortalLoading(true);
    setPortalError(null);
    
    try {
      const response = await axios.post(
        'https://klorah-fast-server.onrender.com/api/create-customer-portal-session',
        {
          userId: auth.currentUser.uid
        },
        {
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
          }
        }
      );

      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        setPortalError('Failed to create portal session. Please try again.');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
      setPortalError(
        error.response?.data?.message || 
        'Unable to access billing portal. Please try again later.'
      );
    } finally {
      setIsPortalLoading(false);
    }
  };

  if (loading || onboardingLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <NavigationBar />
      <div className="min-h-screen bg-white dark:bg-black py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Current Plan Status - Different for Pro vs Free Users */}
          <div className="max-w-4xl mx-auto mb-12">
            {isProUser ? (
              // Pro User Header
              <div className="text-center mb-8">
                <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-[#CBFF5B] text-[#161315]">
                  <CheckIcon className="h-5 w-5" />
                  <span className="font-medium">Active Pro Subscription</span>
                </div>
                <h1 className="text-3xl font-bold text-[#161315] dark:text-white mt-6">
                  Your Pro Benefits
                </h1>
              </div>
            ) : (
              // Free User Header
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-[#161315] dark:text-white">
                  Free Plan
                </h1>
                <p className="mt-3 text-gray-600 dark:text-gray-400">
                  2 free pro documents included monthly
                </p>
              </div>
            )}            
            {/* Usage Stats - Styled differently for Pro vs Free */}
            <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6 mb-12">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600 dark:text-gray-400">Invoices</p>
                  {isProUser ? (
                    <div className="flex items-baseline gap-2">
                      <p className="text-2xl font-bold">{monthlyInvoiceCount}</p>
                      <span className="text-[#CBFF5B]">Unlimited</span>
                    </div>
                  ) : (
                    <p className="text-2xl font-bold">{monthlyInvoiceCount} / {monthlyInvoiceLimit}</p>
                  )}
                </div>
                <div>
                  <p className="text-gray-600 dark:text-gray-400">Agreements</p>
                  {isProUser ? (
                    <div className="flex items-baseline gap-2">
                      <p className="text-2xl font-bold">{monthlyAgreementCount}</p>
                      <span className="text-[#CBFF5B]">Unlimited</span>
                    </div>
                  ) : (
                    <p className="text-2xl font-bold">{monthlyAgreementCount} / {monthlyAgreementLimit}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Section Title - Different for Pro vs Free */}
            <div className="text-center">
              <h2 className="text-4xl font-bold text-[#161315] dark:text-white">
                {isProUser ? 'Manage Your Subscription' : 'Upgrade Your Plan'}
              </h2>
              <p className="mt-6 text-xl text-gray-600 dark:text-gray-400">
                {isProUser 
                  ? 'Change or cancel your plan at any time'
                  : 'Start with 2 free pro documents monthly, upgrade anytime'}
              </p>
              {isProUser && (
                <div className="mt-6">
                  <button
                    onClick={createCustomerPortalSession}
                    disabled={isPortalLoading}
                    className={`bg-[#161315] dark:bg-white text-white dark:text-[#161315] px-6 py-2 rounded-lg font-medium 
                      transition-all ${isPortalLoading ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'}`}
                  >
                    {isPortalLoading ? (
                      <span className="flex items-center gap-2">
                        <div className="animate-spin h-4 w-4 border-2 border-white dark:border-[#161315] border-t-transparent rounded-full" />
                        Loading...
                      </span>
                    ) : (
                      'Manage Membership'
                    )}
                  </button>
                  
                  {portalError && (
                    <p className="mt-2 text-sm text-red-500">
                      {portalError}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Plan Card with Toggle */}
          <div className="mt-20 max-w-2xl mx-auto">
            <div className="relative bg-[#161315] dark:bg-white rounded-2xl shadow-xl p-10">
              <div className="absolute top-0 right-0 -translate-y-1/2 px-4 py-1 rounded-full text-sm font-semibold bg-[#CBFF5B] text-[#161315]">
                Pro Plan
              </div>

              {/* Billing Toggle */}
              <div className="flex justify-center items-center gap-4 mb-8">
                <span className={`text-lg ${billingPeriod === 'monthly' ? 'text-[#CBFF5B]' : 'text-white dark:text-[#161315]'}`}>
                  Monthly
                </span>
                <button 
                  onClick={() => setBillingPeriod(billingPeriod === 'monthly' ? 'quarterly' : 'monthly')}
                  className="relative w-14 h-8 bg-gray-700 dark:bg-gray-300 rounded-full transition-colors"
                >
                  <div className={`absolute top-1 h-6 w-6 bg-[#CBFF5B] rounded-full transition-all ${
                    billingPeriod === 'quarterly' ? 'left-7' : 'left-1'
                  }`} />
                </button>
                <span className={`text-lg ${billingPeriod === 'quarterly' ? 'text-[#CBFF5B]' : 'text-white dark:text-[#161315]'}`}>
                  Quarterly
                </span>
              </div>

              <div>
                <div className="text-center">
                  <div className="flex justify-center items-baseline">
                    <span className="text-4xl font-bold text-white dark:text-[#161315]">
                      ${billingPeriod === 'monthly' ? '15' : '8'}
                    </span>
                    <span className="ml-2 text-xl text-gray-400 dark:text-gray-600">/month</span>
                  </div>
                  {billingPeriod === 'quarterly' && (
                    <p className="mt-2 text-gray-400 dark:text-gray-600">
                      billed quarterly ($24 per quarter)
                    </p>
                  )}
                </div>

                <div className="mt-10">
                  <ul className="space-y-5">
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Unlimited invoices
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Unlimited client agreements
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Premium features included
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Priority support
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Early access to new features
                    </li>
                    <li className="flex items-center text-white dark:text-[#161315]">
                      <span className="mr-4 text-[#CBFF5B]">✓</span>
                      Premium templates library
                    </li>
                  </ul>
                </div>

                <button
                  onClick={() => {
                    setLoading(true);
                    createStripeCustomer().then(data => {
                      if (data) {
                        createCheckoutSession({
                          ...data,
                          billingPeriod
                        });
                      }
                    });
                  }}
                  className="mt-10 w-full bg-[#CBFF5B] text-[#161315] py-4 px-6 rounded-lg font-semibold transition-all shadow-lg hover:opacity-90"
                >
                  {isProUser 
                    ? `Switch to ${billingPeriod === 'monthly' ? 'Monthly' : 'Quarterly'} Plan`
                    : `Get Started with ${billingPeriod === 'monthly' ? 'Monthly' : 'Quarterly'} Plan`
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
