import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { collection, getDocs, updateDoc, doc, getDoc, query, where } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5Bg0VqwUulU6n03zQ0Y6XkF5eRGFqEN8",
  authDomain: "klorah-c4dc2.firebaseapp.com",
  projectId: "klorah-c4dc2",
  storageBucket: "klorah-c4dc2.appspot.com",
  messagingSenderId: "532276017665",
  appId: "1:532276017665:web:42b06f78bb5fcc13e7de00",
  measurementId: "G-F1MNVNGFR0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Function to fetch all projects
export const fetchProjects = async () => {
  const auth = getAuth();
  const userId = auth.currentUser?.uid;
  
  if (!userId) {
    throw new Error('User not authenticated');
  }

  const db = getFirestore();
  const projectsRef = collection(db, 'projects');
  const q = query(projectsRef, where('userId', '==', userId));
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// Function to update task status
export const updateTaskStatus = async (projectId, taskIndex, newStatus) => {
  const projectRef = doc(db, 'projects', projectId);
  const projectSnapshot = await getDoc(projectRef);
  if (projectSnapshot.exists()) {
    const projectData = projectSnapshot.data();
    const updatedTasks = projectData.tasks.map((task, index) =>
      index === taskIndex ? { ...task, status: newStatus } : task
    );
    await updateDoc(projectRef, { tasks: updatedTasks });
  }
};

export const fetchPendingBookings = async () => {
  const auth = getAuth();
  const userId = auth.currentUser?.uid;
  
  if (!userId) {
    throw new Error('User not authenticated');
  }

  const db = getFirestore();
  const bookingsRef = collection(db, 'bookings');
  const q = query(bookingsRef, 
    where('userId', '==', userId),
    where('status', '==', 'pending')
  );
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export default app;
export { analytics, auth, db, storage };
