import { getFirestore, doc, getDoc, updateDoc, addDoc, collection, query, where, getDocs } from 'firebase/firestore';

export const sendEmail = async (to, subject, html_content) => {
  try {
    const response = await fetch('https://klorah-fast-server.onrender.com/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        to,
        subject,
        html_content
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const handleReferralCompletion = async (userId, invitationId) => {
  const firestore = getFirestore();
  
  try {
    // Update invitation status
    const inviteRef = doc(firestore, 'invitations', invitationId);
    await updateDoc(inviteRef, {
      status: 'completed',
      completedAt: new Date(),
      rewardPaid: false
    });

    // Get invitation details
    const inviteDoc = await getDoc(inviteRef);
    const inviteData = inviteDoc.data();

    // Get inviter's details
    const inviterDoc = await getDoc(doc(firestore, 'users', inviteData.inviterId));
    const inviterData = inviterDoc.data();

    // Send notification email using the sendEmail function
    const emailContent = `
      <h1>New Referral Reward Due</h1>
      <p>A referred user has completed their onboarding!</p>
      <p><strong>Inviter:</strong> ${inviterData.username} (${inviterData.email})</p>
      <p><strong>Inviter ID:</strong> ${inviteData.inviterId}</p>
      <p><strong>Invitation ID:</strong> ${invitationId}</p>
      <p><strong>Completed At:</strong> ${new Date().toLocaleString()}</p>
      <p><strong>Reward Amount:</strong> $10</p>
    `;

    await sendEmail(
      'elvin@klorah.com',
      'New Referral Reward Due - $10',
      emailContent
    );

  } catch (error) {
    console.error('Error processing referral completion:', error);
  }
};

export const generateInviteLink = async (userId) => {
  const firestore = getFirestore();
  
  try {
    // Generate a unique code (you can adjust the format as needed)
    const inviteCode = `${userId.slice(0, 6)}-${Date.now().toString(36)}`;
    
    // Create invitation record in Firestore
    const inviteData = {
      id: inviteCode,
      inviterId: userId,
      createdAt: new Date(),
      status: 'pending',
      usedBy: null,
      usedAt: null,
      completedAt: null,
      rewardPaid: false
    };
    
    await addDoc(collection(firestore, 'invitations'), inviteData);
    
    return `https://klorah.app/invite/${inviteCode}`;
  } catch (error) {
    console.error('Error generating invite link:', error);
    throw error;
  }
};

export const claimInvitation = async (inviteCode, newUserId) => {
  const firestore = getFirestore();
  
  try {
    // Query for the invitation using the code
    const invitationsRef = collection(firestore, 'invitations');
    const q = query(invitationsRef, where('id', '==', inviteCode));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      throw new Error('Invalid invitation code');
    }

    const inviteDoc = querySnapshot.docs[0];
    const inviteData = inviteDoc.data();

    // Check if invitation is already used
    if (inviteData.status !== 'pending') {
      throw new Error('Invitation has already been used');
    }

    // Update invitation status
    await updateDoc(doc(firestore, 'invitations', inviteDoc.id), {
      status: 'used',
      usedBy: newUserId,
      usedAt: new Date()
    });

    return inviteDoc.id;
  } catch (error) {
    console.error('Error claiming invitation:', error);
    throw error;
  }
}; 