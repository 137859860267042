import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import { sendEmail } from '../../utils/emailService';

const AcceptModal = ({ booking, onClose, onAccept }) => {
  const [emailMessage, setEmailMessage] = useState(
    `Hi ${booking.clientName},\n\nThank you for your booking request! I'm excited to confirm that I can help you with ${booking.service.name}.\n\nI'll be in touch shortly with next steps.\n\nBest regards`
  );
  const [sendEmail, setSendEmail] = useState(true);

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-black rounded-2xl p-8 max-w-lg w-full shadow-2xl">
        <h3 className="text-2xl font-semibold text-[#161315] dark:text-[#CBFF5B] mb-6">
          Accept Booking Request
        </h3>
        
        <div className="mb-6">
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
              className="mr-2"
            />
            Send confirmation email to client
          </label>
          
          {sendEmail && (
            <textarea
              value={emailMessage}
              onChange={(e) => setEmailMessage(e.target.value)}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black text-[#161315] dark:text-white h-40"
            />
          )}
        </div>

        <div className="flex gap-4">
          <button
            onClick={onClose}
            className="flex-1 px-6 py-3 border border-gray-200 dark:border-white text-[#161315] dark:text-white rounded-xl hover:bg-gray-50 dark:hover:bg-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={() => onAccept(sendEmail, emailMessage)}
            className="flex-1 px-6 py-3 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90"
          >
            Accept & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const DeclineModal = ({ booking, onClose, onDecline }) => {
  const [reason, setReason] = useState('');
  const [sendEmail, setSendEmail] = useState(true);

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-black rounded-2xl p-8 max-w-lg w-full shadow-2xl">
        <h3 className="text-2xl font-semibold text-red-600 mb-6">
          Decline Booking Request
        </h3>
        
        <div className="mb-6">
          <label className="block mb-2">Reason (optional)</label>
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="w-full px-4 py-3 rounded-xl border border-gray-200 dark:border-white bg-white dark:bg-black text-[#161315] dark:text-white h-32"
            placeholder="Why are you declining this booking?"
          />
        </div>

        <div className="mb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
              className="mr-2"
            />
            Notify client by email
          </label>
        </div>

        <div className="flex gap-4">
          <button
            onClick={onClose}
            className="flex-1 px-6 py-3 border border-gray-200 dark:border-white text-[#161315] dark:text-white rounded-xl hover:bg-gray-50 dark:hover:bg-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={() => onDecline(sendEmail, reason)}
            className="flex-1 px-6 py-3 bg-red-600 text-white rounded-xl hover:bg-red-700"
          >
            Decline Booking
          </button>
        </div>
      </div>
    </div>
  );
};

const PendingBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    if (!auth.currentUser) return;

    const q = query(
      collection(db, 'bookings'),
      where('freelancerId', '==', auth.currentUser.uid),
      where('status', '==', 'pending')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const bookingsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setBookings(bookingsData);
    });

    return () => unsubscribe();
  }, [auth.currentUser]);

  const handleAccept = async (booking, shouldSendEmail, emailMessage) => {
    try {
      // Update booking status
      await updateDoc(doc(db, 'bookings', booking.id), {
        status: 'accepted',
        acceptedAt: new Date()
      });

      // Create project
      const projectData = {
        projectName: `${booking.service.name} for ${booking.clientName}`,
        clientName: booking.clientName,
        clientEmail: booking.clientEmail,
        status: 'In Progress',
        createdAt: new Date(),
        dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days from now
        userId: auth.currentUser.uid,
        tasks: [
          {
            taskName: 'Initial consultation',
            status: 'incomplete',
            relativeDeadline: 2
          },
          {
            taskName: `Complete ${booking.service.name}`,
            status: 'incomplete',
            relativeDeadline: 28
          }
        ]
      };

      await addDoc(collection(db, 'projects'), projectData);

      if (shouldSendEmail) {
        await sendEmail(
          booking.clientEmail,
          'Booking Confirmed!',
          emailMessage
        );
      }

      toast.success('Booking accepted successfully');
    } catch (error) {
      console.error('Error accepting booking:', error);
      toast.error('Error accepting booking');
    }
  };

  const handleDecline = async (booking, shouldSendEmail, reason) => {
    try {
      await deleteDoc(doc(db, 'bookings', booking.id));

      if (shouldSendEmail) {
        const emailMessage = `
          Hi ${booking.clientName},\n\n
          Thank you for your booking request. Unfortunately, I won't be able to take on this project at this time.
          ${reason ? `\n\nReason: ${reason}` : ''}
          \n\nBest regards
        `;
        
        await sendEmail(
          booking.clientEmail,
          'Booking Update',
          emailMessage
        );
      }

      toast.success('Booking declined');
    } catch (error) {
      console.error('Error declining booking:', error);
      toast.error('Error declining booking');
    }
  };

  if (bookings.length === 0) {
    return null;
  }

  return (
    <div className="mb-16">
      <h3 className="text-2xl font-bold mb-8 text-center text-[#161315] dark:text-white">
        Pending Bookings
      </h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {bookings.map((booking) => (
          <div key={booking.id} className="bg-white dark:bg-black rounded-2xl p-6 border border-gray-200 dark:border-white shadow-lg">
            <div className="mb-4">
              <h4 className="text-xl font-semibold text-[#161315] dark:text-white">
                {booking.service.name}
              </h4>
              <p className="text-gray-600 dark:text-white/60">
                from {booking.clientName}
              </p>
            </div>
            
            <div className="mb-4">
              <p className="text-2xl font-bold text-[#161315] dark:text-[#CBFF5B]">
                {booking.service.currency} {booking.service.price}
              </p>
            </div>
            
            <p className="mb-6 text-[#161315] dark:text-white">
              {booking.message}
            </p>

            <div className="flex gap-4">
              <button
                onClick={() => {
                  setSelectedBooking(booking);
                  setShowAcceptModal(true);
                }}
                className="flex-1 px-4 py-2 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90 transition-opacity"
              >
                Accept
              </button>
              <button
                onClick={() => {
                  setSelectedBooking(booking);
                  setShowDeclineModal(true);
                }}
                className="flex-1 px-4 py-2 border border-red-600 text-red-600 rounded-xl hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors"
              >
                Decline
              </button>
            </div>
          </div>
        ))}
      </div>

      {showAcceptModal && selectedBooking && (
        <AcceptModal
          booking={selectedBooking}
          onClose={() => {
            setSelectedBooking(null);
            setShowAcceptModal(false);
          }}
          onAccept={(sendEmail, message) => {
            handleAccept(selectedBooking, sendEmail, message);
            setShowAcceptModal(false);
            setSelectedBooking(null);
          }}
        />
      )}

      {showDeclineModal && selectedBooking && (
        <DeclineModal
          booking={selectedBooking}
          onClose={() => {
            setSelectedBooking(null);
            setShowDeclineModal(false);
          }}
          onDecline={(sendEmail, reason) => {
            handleDecline(selectedBooking, sendEmail, reason);
            setShowDeclineModal(false);
            setSelectedBooking(null);
          }}
        />
      )}
    </div>
  );
};

export default PendingBookings; 