import React, { useState, useEffect } from 'react';
import { 
  motion,
  useScroll,
  useTransform,
  useMotionValue,
  useVelocity,
  useSpring,
  useAnimationFrame,
  AnimatePresence 
} from 'framer-motion';
import NavigationBar from './LandingPage/NavigationBar';
import Footer from './LandingPage/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faFileContract,
  faUserCircle,
  faBriefcase,
  faBook
} from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Simple container for each slide's content
const SlideContent = ({ children, className = "" }) => (
  <div
    className={`w-full max-w-4xl bg-black p-4 sm:p-6 md:p-8 rounded-lg shadow-lg backdrop-blur-sm overflow-y-auto max-h-[calc(100vh-12rem)] ${className}`}
  >
    {children}
  </div>
);

// A simple arrow button for navigating slides
const NavigationArrow = ({ direction, onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`
      absolute top-1/2 transform -translate-y-1/2 
      ${direction === 'left' ? 'left-2 sm:left-4' : 'right-2 sm:right-4'} 
      bg-[#CBFF5B] hover:bg-[#b8e651] disabled:bg-gray-400 
      text-[#161315] rounded-full p-2 sm:p-3 md:p-4 transition-all duration-200 
      ease-in-out z-10 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
    `}
  >
    <FontAwesomeIcon
      icon={direction === 'left' ? faChevronLeft : faChevronRight}
      className="text-lg sm:text-xl md:text-2xl"
    />
  </button>
);

const ParallaxContainer = ({ children, baseVelocity = 25 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        {[...Array(10)].map((_, i) => (
          <span key={i}>{children} </span>
        ))}
      </motion.div>
    </div>
  );
};

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const PitchDeck = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Function to generate PDF
  const generatePDF = async () => {
    setIsDownloading(true);
    setDownloadProgress(0);
    
    // Force desktop view for PDF generation
    const originalWidth = window.innerWidth;
    Object.defineProperty(window, 'innerWidth', {
      writable: true,
      configurable: true,
      value: 1920
    });

    const pdf = new jsPDF('landscape', 'pt', [1920, 1080]);
    const currentSlideBackup = currentSlide;
    
    try {
      for (let i = 0; i < slides.length; i++) {
        // Set slide and wait for animations to complete
        setCurrentSlide(i);
        await new Promise(resolve => setTimeout(resolve, 600)); // Wait for animations to complete
        
        const slideElement = document.getElementById('current-slide');
        
        // Temporarily disable animations
        const animations = slideElement.querySelectorAll('[data-framer-motion]');
        animations.forEach(el => {
          el.style.opacity = '1';
          el.style.transform = 'none';
        });
        
        const canvas = await html2canvas(slideElement, {
          scale: 2,
          width: 1920,
          height: 1080,
          backgroundColor: '#000000',
          useCORS: true,
          allowTaint: true,
          logging: false
        });
        
        // Re-enable animations
        animations.forEach(el => {
          el.style.removeProperty('opacity');
          el.style.removeProperty('transform');
        });
        
        const imgData = canvas.toDataURL('image/png');
        if (i > 0) pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, 0, 1920, 1080);

        setDownloadProgress(((i + 1) / slides.length) * 100);
      }
      
      pdf.save('klorah-pitch-deck.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Restore original state
      setCurrentSlide(currentSlideBackup);
      Object.defineProperty(window, 'innerWidth', {
        writable: true,
        configurable: true,
        value: originalWidth
      });
      setIsDownloading(false);
      setDownloadProgress(0);
    }
  };

  const slides = [
    // 1. Title Slide
    {
      title: 'Title Slide',
      content: (
        <SlideContent className="text-center flex flex-col items-center justify-center space-y-12">
          <motion.img
            src="/klorahLogo.png"
            alt="Klorah"
            className="h-16 md:h-24 mx-auto" 
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          />
          <motion.h1
            className="text-4xl md:text-7xl font-extrabold text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Klorah
          </motion.h1>
          <p className="text-xl md:text-3xl text-white max-w-3xl">
            Professional client and financial management for freelancers
          </p>
          <div className="inline-block border-2 border-[#CBFF5B] rounded-lg mt-8">
            <p className="text-lg md:text-xl px-6 py-3 font-bold text-white">
              Founder & CEO: Elvin Atwine
            </p>
          </div>
        </SlideContent>
      ),
    },

    // 2. Market Overview
    {
      title: 'Market Overview',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-8">
            The U.S. Freelance Market
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Key Stats */}
            <div className="space-y-6">
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="p-6 rounded-xl border-2 border-[#CBFF5B] bg-black/5"
              >
                <div className="flex items-center gap-4">
                  <div className="text-4xl font-bold text-[#CBFF5B]">76.4M</div>
                  <div>
                    <div className="text-lg font-semibold text-white">U.S. Freelancers</div>
                    <div className="text-sm text-gray-300">38% of Workforce (2024)</div>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="p-6 rounded-xl border-2 border-[#CBFF5B] bg-black/5"
              >
                <div className="flex items-center gap-4">
                  <div className="text-4xl font-bold text-[#CBFF5B]">$1.3T</div>
                  <div>
                    <div className="text-lg font-semibold text-white">Economic Impact</div>
                    <div className="text-sm text-gray-300">Annual Contribution</div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Right Column - Demographics */}
            <div className="space-y-6">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="p-6 rounded-xl border-2 border-gray-700"
              >
                <h3 className="text-xl font-bold mb-4 text-white">Demographics</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-white">Gen Z Freelancers</span>
                    <span className="font-bold text-[#CBFF5B]">52%</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-white">Millennial Freelancers</span>
                    <span className="font-bold text-[#CBFF5B]">44%</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-white">With Postgraduate Degree</span>
                    <span className="font-bold text-[#CBFF5B]">51%</span>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="p-6 rounded-xl border-2 border-gray-700"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-xl font-bold text-white">Average Hourly Rate</h3>
                    <p className="text-sm text-gray-300">Varies by industry & experience</p>
                  </div>
                  <div className="text-3xl font-bold text-[#CBFF5B]">$47.71</div>
                </div>
              </motion.div>
            </div>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-8 p-6 bg-red-900/20 rounded-xl border-2 border-red-800"
          >
            <p className="text-center text-lg text-white">
              <strong>Key Challenge:</strong> Despite industry growth, freelancers lack professional tools for comprehensive client and financial management
            </p>
          </motion.div>
        </SlideContent>
      ),
    },

    // 3. Product Overview
    {
      title: 'Product',
      content: (
        <SlideContent>
          <h2 className="text-4xl font-bold text-white mb-12">
            Your All-in-One Freelance Business Suite
          </h2>

          <div className="grid md:grid-cols-3 gap-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center text-center space-y-8"
            >
              <div className="w-24 h-24 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faFileContract} className="h-12 w-12 text-[#161315]" />
              </div>

              <h3 className="text-2xl font-bold text-white">
                Document Management
              </h3>

              <p className="text-lg text-gray-300 leading-relaxed">
                Generate professional agreements and invoices instantly with AI assistance and automated templates
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col items-center text-center space-y-8"
            >
              <div className="w-24 h-24 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faUserCircle} className="h-12 w-12 text-[#161315]" />
              </div>

              <h3 className="text-2xl font-bold text-white">
                Client Portal
              </h3>

              <p className="text-lg text-gray-300 leading-relaxed">
                Seamless client experience with integrated booking, document signing, and payment processing
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex flex-col items-center text-center space-y-8"
            >
              <div className="w-24 h-24 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faBriefcase} className="h-12 w-12 text-[#161315]" />
              </div>

              <h3 className="text-2xl font-bold text-white">
                Business Tools
              </h3>

              <p className="text-lg text-gray-300 leading-relaxed">
                Comprehensive analytics and insights to track income, manage expenses and grow your business
              </p>
            </motion.div>
          </div>
        </SlideContent>
      ),
    },

    // 4. Current Metrics
    {
      title: 'Metrics',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-12">
            Our Growth Journey
          </h2>

          <div className="flex flex-col space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center space-x-8 p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
              >
                <div className="flex-shrink-0">
                  <div className="text-5xl md:text-7xl font-bold text-[#CBFF5B]">108</div>
                  <div className="text-xl font-medium text-white mt-2">Total Users</div>
                </div>
                <div className="text-lg text-gray-300">
                  With <span className="font-semibold text-white">16 daily active users</span>, our community is growing steadily
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center space-x-8 p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
              >
                <div className="flex-shrink-0">
                  <div className="text-5xl md:text-7xl font-bold text-[#CBFF5B]">$2.7K</div>
                  <div className="text-xl font-medium text-white mt-2">Processed</div>
                </div>
                <div className="text-lg text-gray-300">
                  Total transaction volume processed in <span className="font-semibold text-white">2024</span>
                </div>
              </motion.div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center space-x-8 p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
              >
                <div className="flex-shrink-0">
                  <div className="text-5xl md:text-7xl font-bold text-[#CBFF5B]">10x</div>
                  <div className="text-xl font-medium text-white mt-2">Growth Target</div>
                </div>
                <div className="text-lg text-gray-300">
                  Ambitious scaling plans for both user base and revenue streams
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center space-x-8 p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
              >
                <div className="flex-shrink-0">
                  <div className="text-5xl md:text-7xl font-bold text-[#CBFF5B]">3</div>
                  <div className="text-xl font-medium text-white mt-2">Platforms</div>
                </div>
                <div className="text-lg text-gray-300">
                  Full coverage across iOS, Android, and Web by <span className="font-semibold text-white">June 2024</span>
                </div>
              </motion.div>
            </div>
          </div>
        </SlideContent>
      ),
    },

    // 5. Business Model
    {
      title: 'Business Model',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-12">
            How We Generate Revenue
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="text-2xl font-bold mb-6 text-white">Free Plan</div>
              <div className="text-4xl font-bold mb-6 text-white">$0</div>
              <ul className="text-center space-y-4 text-lg text-white">
                <li>2 documents/month</li>
                <li>Basic features</li>
                <li>Community support</li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col items-center p-8 rounded-xl border-2 border-[#CBFF5B] bg-[#CBFF5B]/5"
            >
              <div className="text-2xl font-bold mb-6 text-white">Quarterly Plan</div>
              <div className="text-4xl font-bold mb-6 text-white">$8/mo</div>
              <ul className="text-center space-y-4 text-lg text-white">
                <li>Unlimited documents</li>
                <li>Premium features</li>
                <li>Priority support</li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex flex-col items-center p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="text-2xl font-bold mb-6 text-white">Monthly Plan</div>
              <div className="text-4xl font-bold mb-6 text-white">$15/mo</div>
              <ul className="text-center space-y-4 text-lg text-white">
                <li>Unlimited documents</li>
                <li>Premium features</li>
                <li>Priority support</li>
              </ul>
            </motion.div>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="mt-12 p-8 rounded-xl border-2 border-gray-700"
          >
            <h3 className="text-2xl font-bold mb-6 text-white">Future Revenue Streams</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h4 className="text-xl font-bold mb-3 text-white">Financial Partnerships</h4>
                <p className="text-lg text-white">Revenue sharing with banking and payment processing partners</p>
              </div>
              <div>
                <h4 className="text-xl font-bold mb-3 text-white">Premium Integrations</h4>
                <p className="text-lg text-white">Advanced service integrations with professional tools</p>
              </div>
            </div>
          </motion.div>
        </SlideContent>
      ),
    },

    // 6. Market Strategy
    {
      title: 'Go-to-Market',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-12">
            Growth Strategy
          </h2>
          <div className="grid md:grid-cols-3 gap-12">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center text-center p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="w-20 h-20 mb-8 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faFileContract} className="h-10 w-10 text-[#161315]" />
              </div>
              <h3 className="text-2xl font-bold mb-6 text-white">Content Marketing</h3>
              <p className="text-lg text-white">
                Comprehensive educational resources, guides, and professional development content to empower freelancers
              </p>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col items-center text-center p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="w-20 h-20 mb-8 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faUserCircle} className="h-10 w-10 text-[#161315]" />
              </div>
              <h3 className="text-2xl font-bold mb-6 text-white">Community Building</h3>
              <p className="text-lg text-white">
                Strategic partnerships with freelance communities, co-working spaces and professional organizations
              </p>
            </motion.div>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex flex-col items-center text-center p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="w-20 h-20 mb-8 flex items-center justify-center rounded-full bg-[#CBFF5B]">
                <FontAwesomeIcon icon={faBook} className="h-10 w-10 text-[#161315]" />
              </div>
              <h3 className="text-2xl font-bold mb-6 text-white">Educational Resources</h3>
              <p className="text-lg text-white">
                Free courses, tutorials and learning materials to help freelancers grow their skills and business
              </p>
            </motion.div>
          </div>
        </SlideContent>
      ),
    },

    // 7. Product Roadmap
    {
      title: 'Roadmap',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-12">
            6-12 Month Plan
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="relative p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="absolute -top-4 left-4 bg-[#CBFF5B] text-[#161315] px-4 py-1 rounded-full font-bold">
                Q1
              </div>
              <h3 className="text-2xl font-bold mt-4 mb-4 text-white">Launch Core Platform</h3>
              <p className="text-lg text-white">
                Launch complete freelancer profiles with integrated booking, invoicing and messaging capabilities to streamline client interactions
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="relative p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="absolute -top-4 left-4 bg-[#CBFF5B] text-[#161315] px-4 py-1 rounded-full font-bold">
                Q2
              </div>
              <h3 className="text-2xl font-bold mt-4 mb-4 text-white">Marketing & Education</h3>
              <p className="text-lg text-white">
                Launch organic content marketing and educational resources to help freelancers grow their business
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="relative p-8 rounded-xl border-2 border-gray-700 hover:border-[#CBFF5B] transition-colors"
            >
              <div className="absolute -top-4 left-4 bg-[#CBFF5B] text-[#161315] px-4 py-1 rounded-full font-bold">
                Q3-Q4
              </div>
              <h3 className="text-2xl font-bold mt-4 mb-4 text-white">Scale & Expand</h3>
              <p className="text-lg text-white">
                Scale educational resources and free course offerings while expanding platform capabilities
              </p>
            </motion.div>
          </div>
        </SlideContent>
      ),
    },

    // 8. Team
    {
      title: 'Team',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-12">
            Leadership
          </h2>
          <div className="flex flex-col md:flex-row gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="flex-1 relative"
            >
              <div className="absolute -top-6 -right-6 w-48 h-48 bg-[#CBFF5B]/20 rounded-full blur-2xl"></div>
              <div className="relative p-8 rounded-2xl bg-white/5 backdrop-blur-sm border-2 border-gray-200 dark:border-gray-700 hover:border-[#CBFF5B] dark:hover:border-[#CBFF5B] transition-all hover:shadow-lg hover:shadow-[#CBFF5B]/20">
                {/* <div className="w-24 h-24 mx-auto mb-6 rounded-full overflow-hidden border-4 border-[#CBFF5B]">
                  <img 
                    src="/elvin-profile.jpg" 
                    alt="Elvin Atwine"
                    className="w-full h-full object-cover"
                    onError={(e) => e.target.src = 'https://via.placeholder.com/150'} 
                  />
                </div> */}
                <h3 className="text-2xl font-bold mb-2 text-center text-[#161315] dark:text-white">
                  Elvin Atwine
                </h3>
                <p className="text-lg text-center text-gray-600 dark:text-gray-300 mb-4">
                  Founder & CEO
                </p>
                <div className="space-y-3">
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <p className="text-[#161315] dark:text-white">Business Management @ UConn</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <p className="text-[#161315] dark:text-white">Founded Iveyy Clothing</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <p className="text-[#161315] dark:text-white">Freelance Developer & Photographer</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <p className="text-[#161315] dark:text-white">Product Design @ Kaiya</p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex-1 flex flex-col gap-6"
            >
              <div className="p-6 rounded-xl bg-[#CBFF5B]/5 border-2 border-[#CBFF5B]">
                <h3 className="text-xl font-bold mb-3 text-[#161315] dark:text-white">Mission</h3>
                <p className="text-lg text-gray-600 dark:text-gray-300">
                  As a freelancer myself, I understand our challenges firsthand. Klorah exists to bring the security and benefits of traditional jobs to the creative world of freelancing.
                </p>
              </div>
            </motion.div>
          </div>
        </SlideContent>
      ),
    },

    // 9. Investment
    {
      title: 'Investment',
      content: (
        <SlideContent>
          <h2 className="text-2xl md:text-4xl font-bold text-[#161315] dark:text-white mb-12">
            Investment Opportunity
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Left Column - Key Investment Details */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-8"
            >
              <div className="p-8 rounded-xl bg-[#CBFF5B]/5 border-2 border-[#CBFF5B]">
                <div className="flex items-center gap-4 mb-6">
                  <div className="text-xl font-semibold text-[#161315] dark:text-white">Angel Round</div>
                </div>
                <p className="text-lg text-gray-600 dark:text-gray-300">
                  Seeking angel investment to accelerate growth and product development
                </p>
              </div>

              {/* <div className="p-8 rounded-xl border-2 border-gray-200 dark:border-gray-700">
                <h3 className="text-xl font-bold mb-4 text-[#161315] dark:text-white">Use of Funds</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Product Development</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Marketing & Growth</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Committing 50+ Hours Weekly to Building Klorah</span>
                  </li>
                </ul>
              </div> */}
            </motion.div>

            {/* Right Column - Vision & Contact */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="space-y-8"
            >
              <div className="p-8 rounded-xl border-2 border-gray-200 dark:border-gray-700">
                <h3 className="text-xl font-bold mb-4 text-[#161315] dark:text-white">Our Vision</h3>
                <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
                  To revolutionize freelancing by providing the essential toolkit that empowers professionals to:
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Scale their business efficiently</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Manage clients professionally</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#CBFF5B] rounded-full"></div>
                    <span className="text-[#161315] dark:text-white">Achieve financial stability</span>
                  </li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-[#161315] dark:bg-[#CBFF5B]/10 text-center">
                <h3 className="text-xl font-bold mb-4 text-white dark:text-[#CBFF5B]">Let's Connect</h3>
                <p className="text-lg text-white dark:text-[#CBFF5B] mb-4">Ready to be part of our journey?</p>
                <p className="text-2xl font-medium">
                  <a href="mailto:elvin@klorah.com" className="text-[#CBFF5B] hover:underline">elvin@klorah.com</a>
                </p>
              </div>
            </motion.div>
          </div>
        </SlideContent>
      ),
    }
  ];

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') nextSlide();
      if (e.key === 'ArrowLeft') prevSlide();
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentSlide]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <div className="sticky top-0 z-50 backdrop-blur-sm bg-black/50">
        <NavigationBar isTransparent={true} />
      </div>
      
      <div className="relative w-full h-[calc(100vh-4rem)] mx-auto px-4 flex flex-col justify-center overflow-hidden">
        {!isMobile && <ParallaxContainer>Design • Create • Inspire • Thrive • Succeed • Grow • Design • Create • Inspire • Thrive • Succeed • Grow</ParallaxContainer>}
        
        {/* Navigation Arrows */}
        <NavigationArrow direction="left" onClick={prevSlide} disabled={currentSlide === 0} />
        <NavigationArrow
          direction="right"
          onClick={nextSlide}
          disabled={currentSlide === slides.length - 1}
        />
        
        {/* Slides */}
        <AnimatePresence mode="wait">
          <motion.div
            id="current-slide"
            key={currentSlide}
            initial={{ opacity: 0, x: 40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -40 }}
            transition={{ duration: 0.5 }}
            className="flex-1 flex items-center justify-center aspect-video max-h-[80vh] w-full"
          >
            {slides[currentSlide].content}
          </motion.div>
        </AnimatePresence>

        {/* Dots (Slide Indicators) */}
        <div className="flex justify-center space-x-2 mt-4 mb-8 overflow-x-auto px-4">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`
                w-2 h-2 sm:w-3 sm:h-3 rounded-full transition-all duration-200 flex-shrink-0
                ${index === currentSlide ? 'bg-[#CBFF5B] w-4 sm:w-6' : 'bg-gray-300 dark:bg-gray-600'}
              `}
            />
          ))}
        </div>

        {/* Download Button and Progress */}
        <div className={`
          ${isMobile ? 'fixed bottom-4 right-4' : 'absolute bottom-20 right-4'} 
          z-20 flex items-center gap-2
        `}>
          {isDownloading && (
            <div className="bg-white dark:bg-black rounded-lg px-3 py-1 text-sm">
              {Math.round(downloadProgress)}%
            </div>
          )}
          {/* <button
            onClick={generatePDF}
            disabled={isDownloading}
            className={`
              bg-[#CBFF5B] hover:bg-[#b8e651] text-[#161315] 
              rounded-full p-3 transition-all duration-200
              flex items-center gap-2
              ${isDownloading ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            <FontAwesomeIcon 
              icon={faDownload} 
              className={`text-xl ${isDownloading ? 'animate-pulse' : ''}`} 
            />
            {!isMobile && (isDownloading ? 'Downloading...' : 'Download Deck')}
          </button> */}
        </div>
        
        {!isMobile && <ParallaxContainer>Design • Create • Inspire • Thrive • Succeed • Grow • Design • Create • Inspire • Thrive • Succeed • Grow</ParallaxContainer>}
      </div>
      
      <Footer />
    </div>
  );
};

export default PitchDeck;
