import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase';
import { collection, getDocs, query, where, doc, getDoc, deleteDoc, addDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import NavigationBar from '../NavigationBar';
import BookingModal from './BookingModal';
import { sendEmail, generateInviteLink } from '../utils/emailService';

const WelcomeProfileScreen = () => {
  const navigate = useNavigate();

  const startProfileCreation = () => {
    navigate('/edit-profile', { state: { isNewProfile: true }});
  };

  return (
    <div className="min-h-screen bg-white dark:bg-black">
      <NavigationBar />
      <div className="max-w-3xl mx-auto px-4 py-8 sm:py-16 text-center">
        <h1 className="text-3xl sm:text-4xl font-bold text-[#161315] dark:text-[#CBFF5B] mb-4 sm:mb-6">
          Create Your Professional Profile
        </h1>
        
        <p className="text-base sm:text-lg text-[#161315] dark:text-white mb-6 sm:mb-8">
          Share your work, showcase your services, and let potential clients discover you.
        </p>

        <div className="grid grid-cols-1 gap-4 sm:gap-8 mb-8 sm:mb-12">
          <div className="p-4 sm:p-6 rounded-2xl bg-gray-50 dark:bg-gray-900">
            <h3 className="text-lg sm:text-xl font-semibold text-[#161315] dark:text-white mb-2 sm:mb-3">
              Showcase Services
            </h3>
            <p className="text-sm sm:text-base text-[#161315] dark:text-white">
              List your services with prices and let clients book directly.
            </p>
          </div>

          <div className="p-4 sm:p-6 rounded-2xl bg-gray-50 dark:bg-gray-900">
            <h3 className="text-lg sm:text-xl font-semibold text-[#161315] dark:text-white mb-2 sm:mb-3">
              Portfolio Display
            </h3>
            <p className="text-sm sm:text-base text-[#161315] dark:text-white">
              Show off your best work with a beautiful portfolio gallery.
            </p>
          </div>

          <div className="p-4 sm:p-6 rounded-2xl bg-gray-50 dark:bg-gray-900">
            <h3 className="text-lg sm:text-xl font-semibold text-[#161315] dark:text-white mb-2 sm:mb-3">
              Professional Bio
            </h3>
            <p className="text-sm sm:text-base text-[#161315] dark:text-white">
              Tell your story and highlight your expertise.
            </p>
          </div>
        </div>

        <button
          onClick={startProfileCreation}
          className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90 transition-all font-medium text-base sm:text-lg shadow-lg"
        >
          Create Your Profile
        </button>
      </div>
    </div>
  );
};

const DeleteAccountModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-black rounded-2xl p-4 sm:p-8 w-full max-w-lg shadow-2xl">
        <h3 className="text-xl sm:text-2xl font-semibold text-red-600 mb-3 sm:mb-4">Delete Account</h3>
        <p className="text-sm sm:text-base text-[#161315] dark:text-white mb-4 sm:mb-6">Are you sure you want to delete your account? This action cannot be undone.</p>
        <div className="flex gap-3 sm:gap-4">
          <button
            onClick={onClose}
            className="flex-1 px-4 sm:px-6 py-2 sm:py-3 bg-gray-200 dark:bg-gray-800 text-[#161315] dark:text-white rounded-xl text-sm sm:text-base"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 px-4 sm:px-6 py-2 sm:py-3 bg-red-600 text-white rounded-xl hover:bg-red-700 text-sm sm:text-base"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

const ProfileView = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const { username } = useParams();
  const navigate = useNavigate();
  const isOwnProfile = !username && auth.currentUser?.uid;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        let profileData;
        
        if (username) {
          const profilesRef = collection(db, 'profiles');
          const q = query(profilesRef, where('username', '==', username));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            profileData = {
              id: querySnapshot.docs[0].id,
              ...querySnapshot.docs[0].data()
            };
          } else {
            setError('Profile not found');
          }
        } else {
          if (!auth.currentUser) {
            setError('Please log in to view your profile');
            setLoading(false);
            return;
          }
          
          const profileRef = doc(db, 'profiles', auth.currentUser.uid);
          const profileSnap = await getDoc(profileRef);
          
          if (profileSnap.exists()) {
            profileData = {
              id: profileSnap.id,
              ...profileSnap.data()
            };
          } else {
            setError('Profile not found');
          }
        }

        setProfile(profileData);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      fetchProfile();
    });

    return () => unsubscribe();
  }, [username]);

  const handleSignOut = () => {
    auth.signOut();
    navigate('/');
  };

  const handleDeleteAccount = async () => {
    try {
      if (auth.currentUser) {
        await deleteDoc(doc(db, 'profiles', auth.currentUser.uid));
        await auth.currentUser.delete();
        navigate('/');
      }
    } catch (error) {
      toast.error('Error deleting account: ' + error.message);
    }
  };

  const handleShareProfile = () => {
    const profileUrl = `https://klorah.app/profile/${profile.username}`;
    navigator.clipboard.writeText(profileUrl);
    toast.success('Profile link copied to clipboard!');
  };

  const handleBookingSubmit = async (formData) => {
    try {
      if (!profile || !selectedService) {
        throw new Error('Missing profile or service information');
      }

      // Create pending booking in Firestore
      const bookingData = {
        clientName: formData.name,
        clientEmail: formData.email,
        message: formData.message,
        service: {
          name: selectedService.name,
          description: selectedService.description,
          price: selectedService.price,
          currency: selectedService.currency
        },
        freelancerId: profile.id,
        freelancerEmail: profile.contactInfo.email,
        status: 'pending',
        createdAt: new Date(),
      };

      const bookingRef = await addDoc(collection(db, 'bookings'), bookingData);

      // Send initial email notification
      const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; }
            h2 { color: #2d3748; }
            .details { margin: 20px 0; }
            .price { font-size: 1.2em; font-weight: bold; color: #2d3748; }
            .button { display: inline-block; padding: 10px 20px; background-color: #CBFF5B; color: #161315; text-decoration: none; border-radius: 5px; margin-top: 20px; }
          </style>
        </head>
        <body>
          <div class="container">
            <h2>Hi there,</h2>
            <p>You've received a new booking request on Klorah. Here are the details:</p>
            <div class="details">
              <p><strong>Service:</strong> ${selectedService.name}</p>
              <p><strong>Description:</strong> ${selectedService.description}</p>
              <p class="price"><strong>Price:</strong> ${selectedService.currency} ${selectedService.price}</p>
              <p><strong>Client's Name:</strong> ${formData.name}</p>
              <p><strong>Client's Email:</strong> ${formData.email}</p>
              <p><strong>Client's Message:</strong> ${formData.message || 'No message provided'}</p>
            </div>
            <p>You can manage this booking in your project dashboard.</p>
            <a href="https://klorah.app/projects" class="button">View Dashboard</a>
            <p>We hope this new booking request brings you joy and success!</p>
          </div>
        </body>
        </html>
      `;

      await sendEmail(profile.contactInfo.email, 'New Booking Request', htmlContent);
      setIsModalOpen(false);
      toast.success('Booking request sent successfully');
    } catch (error) {
      console.error('Error submitting booking:', error);
      toast.error('Error sending booking request: ' + error.message);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-4 border-[#CBFF5B] border-t-transparent"></div>
    </div>
  );
  
  if (error === 'Profile not found' && !username && auth.currentUser) {
    return <WelcomeProfileScreen />;
  }

  if (error) return (
    <div className="flex flex-col justify-center items-center h-screen text-[#161315] dark:text-white space-y-4 px-4">
      <svg className="w-12 h-12 sm:w-16 sm:h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>
      <p className="text-base sm:text-lg font-medium text-center">{error}</p>
    </div>
  );
  
  if (!profile) return (
    <div className="flex justify-center items-center h-screen text-[#161315] dark:text-white px-4">
      <p className="text-base sm:text-lg font-medium text-center">No profile data available</p>
    </div>
  );

  return (
    <div>
      {auth.currentUser && <NavigationBar />}
      <div className="max-w-7xl mx-auto px-4 py-8 sm:py-12">
        {/* Header Section */}
        <div className="relative mb-12 sm:mb-16 text-center">
          {isOwnProfile && (
            <div className="hidden sm:flex flex-row gap-4 absolute top-4 right-4">
              <button 
                onClick={() => navigate('/edit-profile')}
                className="inline-flex items-center justify-center px-4 py-2 text-[#161315] dark:text-white rounded-lg hover:bg-gray-50 dark:hover:bg-gray-900 transition-colors text-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                Edit Profile
              </button>
              <button
                onClick={handleShareProfile}
                className="px-6 py-3 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90 transition-colors text-base font-medium"
              >
                Share Profile
              </button>
            </div>
          )}
          
          <div className="relative inline-block">
            {profile.profilePicture ? (
              <img 
                src={profile.profilePicture} 
                alt={profile.name}
                className="w-32 h-32 sm:w-40 sm:h-40 rounded-full object-cover ring-4 ring-white dark:ring-black shadow-xl"
              />
            ) : (
              <div className="w-32 h-32 sm:w-40 sm:h-40 rounded-full bg-[#CBFF5B] flex items-center justify-center text-[#161315] text-3xl sm:text-4xl font-bold">
                {profile.name?.charAt(0)}
              </div>
            )}
          </div>
          
          <h1 className="mt-4 sm:mt-6 text-3xl sm:text-4xl font-bold text-[#161315] dark:text-[#CBFF5B]">
            {profile.name}
          </h1>
          
          {profile.professionalTitle && (
            <h2 className="mt-2 sm:mt-3 text-lg sm:text-xl text-[#161315] dark:text-white">
              {profile.professionalTitle}
            </h2>
          )}
          
          {profile.bio && (
            <p className="mt-3 sm:mt-4 text-sm sm:text-base text-[#161315] dark:text-white max-w-2xl mx-auto leading-relaxed px-4">
              {profile.bio}
            </p>
          )}

          {/* Mobile buttons under bio */}
          <div className="sm:hidden flex flex-col gap-3 mt-6">
            {isOwnProfile && (
              <>
                <button 
                  onClick={() => navigate('/edit-profile')}
                  className="w-full inline-flex items-center justify-center px-4 py-2 bg-white dark:bg-black text-[#161315] dark:text-white rounded-xl hover:bg-gray-50 dark:hover:bg-gray-900 transition-colors shadow-sm border border-[#161315] dark:border-white font-medium text-sm"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  Edit Profile
                </button>
              </>
            )}
            <button
              onClick={handleShareProfile}
              className="w-full px-4 py-2 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90 transition-colors text-sm font-medium"
            >
              Share Profile
            </button>
          </div>
        </div>

        {/* Services Section */}
        {profile.services && profile.services.length > 0 && (
          <div className="mb-12 sm:mb-16">
            <h3 className="text-xl sm:text-2xl font-bold mb-6 sm:mb-8 text-center text-[#161315] dark:text-white">Services</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
              {profile.services.map((service, index) => (
                <div key={index} className="group relative bg-white dark:bg-black rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
                  {service.image && (
                    <div className="relative h-48 sm:h-56 overflow-hidden">
                      <img 
                        src={service.image} 
                        alt={service.name} 
                        className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                    </div>
                  )}
                  
                  <div className="p-4 sm:p-6">
                    <h4 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-[#161315] dark:text-white">{service.name}</h4>
                    <p className="text-sm sm:text-base text-[#161315] dark:text-white mb-4 line-clamp-3">{service.description}</p>
                    <div className="flex items-center justify-between">
                      <p className="text-xl sm:text-2xl font-bold text-[#161315] dark:text-[#CBFF5B]">
                        {service.currency} {service.price}
                      </p>
                      <button
                        onClick={() => {
                          setSelectedService(service);
                          setIsModalOpen(true);
                        }}
                        className="px-4 sm:px-6 py-2 bg-[#CBFF5B] text-[#161315] rounded-xl hover:opacity-90 transition-colors font-medium text-sm sm:text-base"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Portfolio Section */}
        {profile.portfolio && profile.portfolio.length > 0 && (
          <div className="mb-12 sm:mb-16">
            <h3 className="text-xl sm:text-2xl font-bold mb-6 sm:mb-8 text-center text-[#161315] dark:text-white">Portfolio</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
              {profile.portfolio.map((item, index) => (
                <div key={index} className="group bg-white dark:bg-black rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300">
                  <div className="relative h-48 sm:h-64 overflow-hidden">
                    <img 
                      src={item.image} 
                      alt={item.title} 
                      className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  </div>
                  <div className="p-4 sm:p-6">
                    <h4 className="text-base sm:text-lg font-semibold mb-2 text-[#161315] dark:text-white">{item.title}</h4>
                    {item.description && (
                      <p className="text-sm sm:text-base text-[#161315] dark:text-white line-clamp-2">
                        {item.description}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isOwnProfile && (
          <div className="mt-8 sm:mt-12 text-center flex flex-col gap-4">
            <button
              onClick={handleSignOut}
              className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 bg-gray-200 dark:bg-gray-800 text-[#161315] dark:text-white rounded-xl hover:bg-gray-300 dark:hover:bg-gray-700 transition-colors text-sm sm:text-base mx-auto"
            >
              Sign Out
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}              
              className="text-red-600 hover:text-red-700 transition-colors text-sm sm:text-base"
            >
              Delete Account
            </button>
          </div>
        )}

        <div className="mt-12 sm:mt-16 text-center text-xs sm:text-sm text-gray-500 dark:text-gray-400">
          Powered by{' '}
          <a 
            href="https://klorah.app" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-[#CBFF5B] hover:underline font-medium"
          >
            Klorah
          </a>
        </div>

        <BookingModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          service={selectedService}
          onSubmit={handleBookingSubmit}
        />

        <DeleteAccountModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteAccount}
        />
      </div>
    </div>
  );
};

export default ProfileView;
