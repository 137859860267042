import React from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-4xl p-12 m-8">
        <button onClick={goBack} className="mb-8 flex items-center gap-2 text-black hover:opacity-70 transition-opacity">
          <span>←</span>
          <span className="font-medium">Back</span>
        </button>
        
        <h1 className="text-4xl font-bold mb-6 text-center text-black">Privacy Policy</h1>
        <p className="mb-8 text-center text-black/60">Last Updated: January 5, 2024</p>

        <section className="space-y-12">
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Introduction</h2>
            <p className="text-black/80 leading-relaxed">
              At Klorah, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices for collecting, using, and safeguarding your data while using our AI-powered billing and contract management platform.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Information Collection</h2>
            <p className="text-black/80 leading-relaxed mb-4">
              We collect and process the following types of information:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-black/80">
              <li>Personal identification information (name, email address, phone number)</li>
              <li>Business information (company name, address, tax ID)</li>
              <li>Payment information (processed securely through Stripe)</li>
              <li>Usage data (how you interact with our platform)</li>
              <li>Communication preferences</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Third-Party Services</h2>
            
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-medium mb-2 text-black">Stripe</h3>
                <p className="text-black/80 leading-relaxed">
                  We use Stripe as our payment processor. They maintain PCI compliance and handle all payment data securely. We never store your complete payment information on our servers.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-medium mb-2 text-black">Firebase</h3>
                <p className="text-black/80 leading-relaxed">
                  Firebase powers our backend infrastructure, providing secure data storage, authentication, and analytics. All data is encrypted in transit and at rest.
                </p>
              </div>

              <div>
                <h3 className="text-xl font-medium mb-2 text-black">OpenAI</h3>
                <p className="text-black/80 leading-relaxed">
                  Our AI features are powered by OpenAI's models. While we send data to OpenAI for processing, it's anonymized where possible and not used for model training.
                </p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Information Use</h2>
            <p className="text-black/80 leading-relaxed">
              We use your information to:
            </p>
            <ul className="list-disc pl-6 space-y-3 mt-3 text-black/80">
              <li>Provide and improve our services</li>
              <li>Process payments and manage your account</li>
              <li>Send important updates and notifications</li>
              <li>Analyze usage patterns to enhance user experience</li>
              <li>Provide customer support</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Security Measures</h2>
            <p className="text-black/80 leading-relaxed">
              We implement industry-standard security measures including:
            </p>
            <ul className="list-disc pl-6 space-y-3 mt-3 text-black/80">
              <li>End-to-end encryption for sensitive data</li>
              <li>Regular security audits and penetration testing</li>
              <li>Multi-factor authentication options</li>
              <li>Automated threat detection and prevention</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4 text-black">Contact Information</h2>
            <p className="text-black/80 leading-relaxed">
              For privacy-related questions or concerns, please contact us at:
            </p>
            <div className="mt-4 p-6 bg-black/5 rounded-lg">
              <p className="text-black/80">Email: <a href="mailto:elvin@klorah.com" className="text-black underline hover:opacity-70">elvin@klorah.com</a></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
