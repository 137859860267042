import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdClipboard, IoIosArrowBack } from 'react-icons/io';
import { db } from '../../Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from '../../NavigationBar';
import { generatePdfFromHtml } from './pdfUtils';
import mixpanel from 'mixpanel-browser';
import DarkModeButton from '../../DarkModeButton';

const Modal = ({ title, children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-sm w-full mx-4">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-semibold text-black dark:text-white">{title}</h3>
          <button className="text-black dark:text-white hover:text-gray-500 dark:hover:text-gray-300" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

const ShareAgreement = () => {
  const { agreementId } = useParams();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [pdfContent, setPdfContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [editInstruction, setEditInstruction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const fetchAgreement = async () => {
      const docRef = doc(db, 'agreements', agreementId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const agreementData = docSnap.data();
        setAgreement(agreementData);
        setPdfContent(agreementData.text);
        setEditedText(agreementData.text);
        mixpanel.track('Agreement Fetched', { agreementId: agreementId });
      } else {
        console.log('No such document!');
      }
    };

    fetchAgreement();
  }, [agreementId]);

  const copyLinkToClipboard = () => {
    const link = `https://klorah.app/share-agreement/${agreementId}`;
    navigator.clipboard.writeText(link).then(
      () => {
        toast.success('Link copied to clipboard');
        mixpanel.track('Link Copied', { agreementId: agreementId });
      },
      (err) => {
        toast.error('Could not copy text: ' + err);
      }
    );
  };

  const generatePdfBlob = async () => {
    if (pdfContent) {
      generatePdfFromHtml(pdfContent, agreement.name);
    }
  };

  const sendEmail = async (email, subject, htmlContent) => {
    try {
      const response = await fetch('https://klorah-fast-server.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: email,
          subject: subject,
          html_content: htmlContent
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const result = await response.json();
      toast.success('Email sent successfully');
      console.log('Email response:', result);
    } catch (error) {
      toast.error('Error sending email: ' + error.message);
      console.error('Error sending email:', error);
    }
  };

  const handleRequestSignature = (client = false) => {
    setShowModal(false);
    const agreementData = encodeURIComponent(JSON.stringify(agreement));
    const subject = 'Your signature has been requested in this document';
    const htmlContent = `
      <p>Hello,</p>
      <p>An agreement has been prepared for you. This agreement includes important details such as the scope of work, deliverables, and timeline.</p>
      <p>Please review and sign the document at your earliest convenience. The document can be accessed via the following link:</p>
      <a href="https://klorah.app/signature/${agreementId}?data=${agreementData}">Access Document</a>
      <p>For any questions or clarifications, feel free to reach out.</p>
      <p>Thank you for your attention to this matter.</p>
      <p>Best Regards,</p>
      <p>The Klorah Team</p>
    `;

    if (client && clientEmail) {
      sendEmail(clientEmail, subject, htmlContent);
    } else {
      navigate(`/signature/${agreementId}?data=${agreementData}`);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, 'agreements', agreementId);
      await updateDoc(docRef, { text: editedText });
      setAgreement({ ...agreement, text: editedText });
      setPdfContent(editedText);
      setIsEditing(false);
      toast.success('Agreement updated successfully');
      mixpanel.track('Agreement Edited', { agreementId: agreementId });
    } catch (error) {
      toast.error('Error updating agreement: ' + error.message);
    }
  };

  const handleAIEdit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://klorah-fast-server.onrender.com/api/ai-edit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: editedText,
          instruction: editInstruction
        })
      });

      if (!response.ok) {
        throw new Error('Failed to edit with AI');
      }

      const result = await response.json();
      setEditedText(result.edited_text);
      toast.success('AI edit applied successfully');
    } catch (error) {
      toast.error('Error applying AI edit: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isUserAuthenticated = auth.currentUser;

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      {isUserAuthenticated && (
        <div className="fixed top-0 w-full z-50 bg-white dark:bg-black">
          <NavigationBar />
          <DarkModeButton />
        </div>
      )}
      <div className={`container mx-auto p-4 ${isUserAuthenticated ? 'pt-20' : ''}`}>
        <div className="max-w-5xl mx-auto bg-white dark:bg-gray-800 rounded-lg border-2 border-gray-300 dark:border-gray-700 p-6">
          {isUserAuthenticated && (
            <div className="flex justify-start pb-5">
              <IoIosArrowBack
                size={28}
                className="text-gray-800 dark:text-gray-300 cursor-pointer"
                onClick={() => window.history.back()}
              />
            </div>
          )}
          <h1 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
            {isUserAuthenticated ? 'Share Your Agreement' : 'Your Agreement'}
          </h1>
          <div className="flex flex-col sm:flex-row justify-between gap-4">
            {isUserAuthenticated && (
              <button
                onClick={handleEdit}
                className="flex items-center justify-center px-4 py-2 bg-black hover:bg-gray-800 dark:bg-white dark:hover:bg-gray-300 text-white dark:text-black font-semibold rounded-lg transition duration-300"
              >
                Edit Agreement
              </button>
            )}
            <button
              onClick={copyLinkToClipboard}
              className="flex items-center justify-center px-4 py-2 bg-black hover:bg-gray-800 dark:bg-white dark:hover:bg-gray-300 text-white dark:text-black font-semibold rounded-lg transition duration-300"
            >
              <IoMdClipboard className="mr-2" /> {isUserAuthenticated ? 'Copy Link For Client' : 'Copy Link'}
            </button>
          </div>
          <div className="flex flex-col justify-between mt-4 gap-2">
            <button
              onClick={generatePdfBlob}
              className="w-full bg-black hover:bg-gray-800 dark:bg-white dark:hover:bg-gray-300 text-white dark:text-black font-bold py-2 rounded-lg transition duration-300"
            >
              Download as PDF
            </button>
            {isUserAuthenticated ? (
              <button
                onClick={() => setShowModal(true)}
                className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 rounded-lg transition duration-300"
              >
                Sign with Klorah
              </button>
            ) : (
              <button
                onClick={() => navigate(`/signature/${agreementId}`)}
                className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 rounded-lg transition duration-300"
              >
                Sign Agreement
              </button>
            )}
          </div>
          {agreement && (
            <>
              <h2 className="font-bold pt-4 pb-4 text-gray-800 dark:text-gray-100">Agreement:</h2>
              {isEditing ? (
                <div>
                  <textarea
                    className="w-full h-64 p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                  />
                  <input
                    type="text"
                    className="w-full mt-2 p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
                    placeholder="Enter AI edit instruction (e.g., 'Make it more formal')"
                    value={editInstruction}
                    onChange={(e) => setEditInstruction(e.target.value)}
                  />
                  <div className="mt-2 flex flex-col sm:flex-row justify-end gap-2">
                    <div className="flex flex-col sm:flex-row justify-end gap-2">
                      <button
                        onClick={() => setEditInstruction('Make it longer')}
                        className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded-lg"
                      >
                        Make it longer
                      </button>
                      <button
                        onClick={() => setEditInstruction('Make it shorter')}
                        className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded-lg"
                      >
                        Make it shorter
                      </button>
                      <button
                        onClick={() => setEditInstruction('Make it more formal')}
                        className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded-lg"
                      >
                        Make it more formal
                      </button>
                    </div>
                    <button
                      onClick={handleAIEdit}
                      className="px-4 py-2 bg-black hover:bg-black text-white font-bold rounded-lg"
                    >
                      {isLoading ? 'Applying AI Edit...' : 'Apply AI Edit'}
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white font-bold rounded-lg"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="text-gray-700 dark:text-gray-200 whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: agreement.text }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={true} />
      {showModal && (
        <Modal title="Request Signature" onClose={() => setShowModal(false)}>
          <div className="mb-4">
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Do you want to sign the document yourself or send it to your client for signature?
            </p>
            <button
              className="w-full bg-black hover:bg-gray-800 dark:bg-white dark:hover:bg-gray-300 text-white dark:text-black font-bold py-2 rounded-lg mb-4"
              onClick={() => handleRequestSignature()}
            >
              Sign Myself
            </button>
            <input
              type="email"
              id="clientEmail"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
              placeholder="Enter client's email"
            />
            <button
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 rounded-lg mt-2"
              onClick={() => handleRequestSignature(true)}
            >
              Send to Client
            </button>
          </div>
        </Modal>
      )}
      <div style={{ display: 'none' }}>
        <div id="pdfContent" dangerouslySetInnerHTML={{ __html: pdfContent }} />
      </div>
    </div>
  );
};

export default ShareAgreement;